import * as c from "../../constants";
import API from '../../api';

export const getKindergartenGeneralAbsenceMonthSettings = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/kindergarten-general-absence-month`, config)

        dispatch({ type: c.GET_KINDERGARTEN_ABSENCE_MONTH_SETTINGS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const storeKindergartenGeneralAbsenceMonthSettings = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/kindergarten-general-absence-month`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const deleteKindergartenGeneralAbsenceMonthSettings = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/kindergarten-general-absence-month/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}