import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { getPayments, deletePayment } from '../../../redux/actions/kindergarten/payments';
import { getStudents } from '../../../redux/actions/kindergarten/students';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from './forms/Add';
import styles from './styles.module.scss';
import { getMonthName } from '../../../helpers/months';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';
import { monthsOptions, getCurrentMotnh } from '../../../helpers/months';
import { yearsOptions, getCurrentYear } from '../../../helpers/years';

const Payments = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const payments = useSelector(state => state.payments.payments);
    const students = useSelector(state => state.students.students);

    const [loading, setLoading] = useState(true);

    const [form, setForm] = useState("none");
    const [totalPrice, setTotalPrice] = useState(0);

    const [type, setType] = useState('monthly');
    const [month, setMonth] = useState(getCurrentMotnh());
    const [year, setYear] = useState(getCurrentYear());

    useEffect(() => {
        let price = 0;
        payments.forEach(element => {
            price += element.price;
        });
        setTotalPrice(price);
    }, [payments]);

    useEffect(() => {
        setLoading(true);
        dispatch(getStudents()).then(res => {
            dispatch(getPayments(type, month, year)).then(res => {
                setLoading(false);
            })
        })
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        dispatch(getPayments(type, month, year)).then(res => {
            setLoading(false);
        })
    }, [type, month, year]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const deletePaymentHandler = (id) => {
        setLoading(true);
        dispatch(deletePayment(id)).then(res => {
            dispatch(getPayments(type, month, year)).then(res => {
                setLoading(false);
            })
        })
    }

    const onFinish = () => {
        setForm("none");
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getPayments(type, month, year)).then(res => {
            setLoading(false);
        })
    }

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Filtro sipas</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Filtro sipas"
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <MenuItem key="1" value="monthly">Sipas muajit</MenuItem>
                                    <MenuItem key="2" value="yearly">Sipas vitit</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Viti</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={year}
                                    label="Viti"
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    {yearsOptions.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3'>
                            {type === 'monthly' && (
                                <FormControl fullWidth size='small' required>
                                    <InputLabel id="demo-simple-select-label">Muaji</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={month}
                                        label="Muaji"
                                        onChange={(e) => setMonth(e.target.value)}
                                    >
                                        {monthsOptions.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className='col-md-4 d-flex justify-content-end'>
                            <Button 
                                variant="contained" 
                                onClick={openAddForm}
                            >
                                <AddCircleOutlineIcon fontSize="small" />
                            </Button>
                        </div>
                    </div>
                    <hr></hr>
                    {totalPrice > 0 && (
                        <p className='mb-2'>Totali: {totalPrice} €</p>
                    )}
                    <div className={styles.TableWrapper}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Studenti</b></TableCell>
                                        <TableCell><b>Periudha</b></TableCell>
                                        <TableCell><b>Çmimi</b></TableCell>
                                        <TableCell><b>Data</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {payments && payments.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>
                                                {item.student.name}
                                            </TableCell>
                                            <TableCell>
                                                {getMonthName(item.month)} / {item.year}
                                            </TableCell>
                                            <TableCell>
                                                {item.price} €
                                            </TableCell>
                                            <TableCell>
                                                {moment(item.created_at).format('LLL')}
                                            </TableCell>
                                            <TableCell>
                                                {/* <ReactToPrint
                                                    onBeforeGetContent={() => {
                                                        setPaymentPrint(item);
                                                        return componentRef.current;
                                                    }}
                                                    trigger={() => (
                                                        <IconButton>
                                                            <LocalPrintshopIcon fontSize='small' />
                                                        </IconButton>
                                                    )}
                                                    content={() => componentRef}
                                                /> */}
                                                <IconButton disabled={loading} onClick={() => navigate(`/kindergarten-payment/${item.id}`)}>
                                                    <PrintIcon fontSize='small' />
                                                </IconButton>
                                                <IconButton disabled={loading} onClick={() => deletePaymentHandler(item.id)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {payments.length === 0 && (
                            <p className='text-center mt-4'>Nuk ka të dhëna</p>
                        )}
                    </div>
                </div>
            )}

            <RightDrawer
                title={form === "add" ? "Krijo pagesë" : ""}
                width={600}
            >
                {form === "add" && <Add onFinish={onFinish} students={students} /> }
            </RightDrawer>
        </div>
    );
};

export default Payments;