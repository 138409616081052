import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCountries, getCities } from '../../../redux/actions/general';
import { getStudents } from '../../../redux/actions/kindergarten/students';
import { getStaff } from '../../../redux/actions/kindergarten/staff';
import { getCategories } from '../../../redux/actions/kindergarten/categories';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, TableContainer, FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TextField } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import EditIcon from '@mui/icons-material/Edit';
import MoreIcon from '@mui/icons-material/More';
import Add from './forms/Add';
import Edit from './forms/Edit';
import styles from './styles.module.scss';

const Students = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const drawer = useSelector(state => state.app.isRightDrawerOpen);
    const students = useSelector(state => state.students.students);
    const staff = useSelector(state => state.staff.staff);
    const categories = useSelector(state => state.categories.categories);

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [teacherId, setTeacherId] = useState('all');
    const [categoryId, setCategoryId] = useState('all');
    const [status, setStatus] = useState('all');
    const [items, setItems] = useState();
    const [selectedItem, setSelectedItem] = useState();

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getCountries()).then(res => {
            dispatch(getCities()).then(res => {
                dispatch(getStudents()).then(res => {
                    dispatch(getStaff()).then(res => {
                        dispatch(getCategories()).then(res => {
                            setLoading(false);
                        })
                    })
                })
            })
        })
    }, [dispatch]);

    const searchingData = (value) => {
        setSearch(value);
    }

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const selectItem = (item) => {
        setForm("edit");
        dispatch(toggleDrawer(true));
        setSelectedItem(item);
    }

    const onFinish = () => {
        setForm("none");
        setSelectedItem();
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getStudents()).then(res => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (!drawer) {
            setSelectedItem();
            setForm("none");
        }
    }, [drawer]);

    useEffect(() => {
        if (search || categoryId !== "all" || teacherId !== "all" || status !== "all") {
            let filterItems = students.filter((item) => item.name.toLowerCase().includes(search));
            if (teacherId !== "all") {
                filterItems = filterItems.filter((item) => item.student_profile.teacher_id === teacherId); 
            }
            if (categoryId !== "all") {
                filterItems = filterItems.filter((item) => item.student_profile.category_id === categoryId); 
            }
            if (status !== "all") {
                filterItems = filterItems.filter((item) => item.status === status);
            }
            setItems(filterItems);
        } else {
            setItems(students);
        }
    }, [search, students, categoryId, teacherId, status]);

    return (
        <div className={styles.StudentsWrapper}>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label="Kërko"
                                size="small"
                                type='text'
                                value={search}
                                onChange={(e) => searchingData(e.target.value)}
                            />
                        </div>
                        <div className='col-md-2'>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Filtro sipas mësueses</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={teacherId}
                                    label="Filtro sipas mësueses"
                                    onChange={(e) => setTeacherId(e.target.value)}
                                >
                                    <MenuItem key="all" value="all">Të gjithë</MenuItem>
                                    {staff.length > 0 && staff.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name} {!item.status && '(jo aktiv)'}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-2'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Filtro sipas kategorisë / klasës</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={categoryId}
                                    label="Filtro sipas kategorisë / klasës"
                                    name="device_id"
                                    onChange={(e) => setCategoryId(e.target.value)}
                                >
                                    <MenuItem key="all" value="all">Të gjithë</MenuItem>
                                    {categories.length > 0 && categories.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-2'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Filtro sipas statusit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Filtro sipas statusit"
                                    name="status"
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <MenuItem key="all" value='all'>Të gjithë</MenuItem>
                                    <MenuItem key="active" value={1}>Aktiv</MenuItem>
                                    <MenuItem key="inactiv" value={0}>Jo aktiv</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-4 d-flex justify-content-end'>
                            <Button 
                                variant="contained" 
                                onClick={openAddForm}
                            >
                                <AddCircleOutlineIcon fontSize="small" />
                            </Button>
                        </div>
                    </div>
                    <hr></hr>
                    <div className={styles.TableWrapper}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Emri dhe mbiemri</b></TableCell>
                                        <TableCell><b>Qyteti / Shteti</b></TableCell>
                                        <TableCell><b>Adresa</b></TableCell>
                                        <TableCell><b>Kontakti</b></TableCell>
                                        <TableCell><b>Mësuesja</b></TableCell>
                                        <TableCell><b>Klasa</b></TableCell>
                                        <TableCell><b>Statusi</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items && items.map((item, index) => (
                                        <TableRow hover key={index} className={selectedItem && selectedItem.id === item.id ? styles.ActiveItem : ''}>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.student_profile && `${item.student_profile.city.name} / ${item.student_profile.city.country.name} `}
                                            </TableCell>
                                            <TableCell>
                                                {item.student_profile && item.student_profile.address}
                                            </TableCell>
                                            <TableCell>
                                                {item.student_profile && item.student_profile.contact_phone}
                                            </TableCell>
                                            <TableCell>
                                                {item.student_profile && item.student_profile.teacher && item.student_profile.teacher.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.student_profile && item.student_profile.category && item.student_profile.category.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.status ? 'Aktiv' : 'Jo aktiv'}
                                            </TableCell>
                                            <TableCell className='d-flex justify-content-end'>
                                                <IconButton onClick={() => selectItem(item)}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                                <IconButton onClick={() => navigate(`/kindergarten-student/${item.id}`)}>
                                                    <MoreIcon fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}

            <RightDrawer
                title={form === "add" ? "Nxënës i ri" : "Ndrysho të dhënat e nxënësit"}
                width={600}
            >
                {form === "add" && <Add onFinish={onFinish} /> }
                {form === "edit" && <Edit onFinish={onFinish} item={selectedItem} /> }
            </RightDrawer>
        </div>
    );
};

export default Students;