import * as c from "../../constants";
import API from '../../api';

export const getDashboardKindergartenInfos = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/getDashboardKindergartenInfos`, config)

        dispatch({ type: c.GET_DASHBOARD_INFO, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}