import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getFoodsDaily, deleteFoodDaily } from '../../../redux/actions/kindergarten/food';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styles from './styles.module.scss';
import AddFood from './forms/AddFood';

const FoodDaily = () => {
    const dispatch = useDispatch();
    const foods = useSelector(state => state.foodDaily.foodDaily);

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getFoodsDaily()).then(res => {
            setLoading(false);
        })
    }, [dispatch]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const onFinish = () => {
        setForm("none");
        setSelectedItem();
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getFoodsDaily()).then(res => {
            setLoading(false);
        })
    }

    const deleteHandler = (id) => {
        setLoading(true);
        dispatch(deleteFoodDaily(id)).then(res => {
            dispatch(getFoodsDaily()).then(res => {
                setLoading(false);
            })
        })
    }

    const editHandler = (item) => {
        setSelectedItem(item);
        setForm("edit");
        dispatch(toggleDrawer(true));
    }

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p style={{ padding: 0, margin: 0 }}>Ushqimet që ofrohen nga çerdhja</p>
                        <Button 
                            variant="contained" 
                            onClick={openAddForm}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </Button>
                    </div>
                    <hr></hr>

                    {foods.filter((item) => item.day === '1').length > 0 && (
                        <div className='mb-5'>
                            <h4>E hënë</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Përshkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '1').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>  
                    )}

                    {foods.filter((item) => item.day === '2').length > 0 && (
                        <div className='mb-5'>
                            <h4>E martë</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '2').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>    
                    )}  

                    {foods.filter((item) => item.day === '3').length > 0 && (
                        <div className='mb-5'>
                            <h4>E mërkurë</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '3').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>   
                    )}

                    {foods.filter((item) => item.day === '4').length > 0 && (
                        <div className='mb-5'>
                            <h4>E enjte</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '4').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>  
                    )}

                    {foods.filter((item) => item.day === '5').length > 0 && (
                        <div className='mb-5'>
                            <h4>E premte</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '5').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>    
                    )}

                    {foods.filter((item) => item.day === '6').length > 0 && (
                        <div className='mb-5'>
                            <h4>E shtunë</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '6').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>   
                    )} 

                    {foods.filter((item) => item.day === '7').length > 0 && (
                        <div className='mb-5'>
                            <h4>E diel</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => item.day === '7').map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>  
                    )}


                    {foods.filter((item) => !item.day).length > 0 && (
                        <div className='mb-3'>
                            <h4>Te tjerat</h4>
                            <TableContainer className='mt-3'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Ushqimi</b></TableCell>
                                            <TableCell><b>Koha</b></TableCell>
                                            <TableCell><b>Pershkrimi</b></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {foods && foods.filter((item) => !item.day).map((item, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    {item.title}
                                                </TableCell>
                                                <TableCell>
                                                    {item.type === "breakfast" ? "Mengjesi" : item.type === "lunch" ? "Dreka" : "Snacks"}
                                                </TableCell>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton disabled={loading} onClick={() => deleteHandler(item.id)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>   
                    )}        

                    <RightDrawer
                        title={form === "add" ? "Krijo ushqime ditore" : "Ndrysho ushqimin ditor"}
                        width={600}
                    >
                        {form === "add" && <AddFood onFinish={onFinish} /> }
                        {/* {form === "edit" && <EditCategory onFinish={onFinish} item={selectedItem} />} */}
                    </RightDrawer>
                </div>
            )}
        </div>
    );
};

export default FoodDaily;