import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableRow, TableHead, TableBody, TableCell, IconButton, FormGroup, FormControlLabel, Switch } from '@mui/material';
import toast from 'react-hot-toast';
import { monthsOptions } from '../../../helpers/months';
import { yearsOptions } from '../../../helpers/years';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFromTodayStudentAbsences, getStudentAbsencesBasedOnMonth, storeAbsencesForStudent, deletestudentPlannedAbsence } from '../../../redux/actions/kindergarten/absences';

const Absences = ({ onFinish, studentId }) => {
    const dispatch = useDispatch();
    const absences = useSelector(state => state.absences.absences);
    const absenceHistory = useSelector(state => state.absences.absencesHistory);

    const [loading, setLoading] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());

    const [date, setDate] = useState();
    const [moreThanOneDay, setMoreThanOneDay] = useState(false);
    const [toDate, setToDate] = useState();
    const [reason, setReason] = useState();

    const handleSwitchChange = () => {
        setMoreThanOneDay(!moreThanOneDay);
        setToDate('');
    };

    useEffect(() => {
        setLoading(true);
        dispatch(getFromTodayStudentAbsences(studentId)).then(res => {
            setLoading(false);
        })
    }, [studentId, dispatch]);

    useEffect(() => {
        if (month && year) {
            setLoadingHistory(true);
            dispatch(getStudentAbsencesBasedOnMonth(month, year, studentId)).then(res => {
                setLoadingHistory(false);
            })
        }
    }, [month, year, dispatch, studentId]);

    const onSubmitForm = (e) => {
        e.preventDefault();

        const data = {
            "date": date,
            "to_date": toDate,
            "reason": reason,
            "student_id": studentId
        };

        setLoadingForm(true);
        dispatch(storeAbsencesForStudent(data)).then(res => {
            setLoading(true);
            toast.success('Te dhenat jane ruajtur me sukses');
            dispatch(getFromTodayStudentAbsences(studentId)).then(res => {
                setLoading(false);
            })
            if (month && year) {
                setLoadingHistory(true);
                dispatch(getStudentAbsencesBasedOnMonth(month, year, studentId)).then(res => {
                    setLoadingHistory(false);
                })
            }
            setDate('');
            setToDate('');
            setReason('');
            setLoadingForm(false);
        })
    }

    const deleteAbsenceeHandler = (id) => {
        setLoading(true);
        dispatch(deletestudentPlannedAbsence(id)).then(res => {
            dispatch(getFromTodayStudentAbsences(studentId)).then(res => {
                setLoading(false);
            })
            setLoadingHistory(true);
            dispatch(getStudentAbsencesBasedOnMonth(month, year, studentId)).then(res => {
                setLoadingHistory(false);
            })
        })
    }

    return (
        <div>
            <div>
                <p>Shto mungese te planifikuar</p>
                <form className='mt-3' onSubmit={onSubmitForm}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={moreThanOneDay} onChange={handleSwitchChange} />}
                            label={!moreThanOneDay ? 'Me shume se nje dite' : 'Mungese nje ditore'}
                        />
                    </FormGroup>
                    <div className='row'>
                        <div className='col-md-3 mt-3'>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label={moreThanOneDay ? "Prej" : "Data"}
                                size="small"
                                value={date}
                                type='date'
                                required
                                onChange={(e) => setDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>

                        
                        <div className='col-md-3 mt-3'>
                            {moreThanOneDay && (
                                <TextField
                                    name="name"
                                    variant="outlined"
                                    fullWidth
                                    label="Deri"
                                    size="small"
                                    value={toDate}
                                    type='date'
                                    required
                                    onChange={(e) => setToDate(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        </div>

                        <div className='col-md-6 mt-3'>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label="Aryseja"
                                size="small"
                                value={reason}
                                type='test'
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </div>

                        <div className="text-center mt-4">
                            <Button variant="contained" type="submit" disabled={loadingForm}>
                                Ruaj
                            </Button>
                            {loadingForm && <LinearProgress color="primary" className="mt-1" />}               
                        </div>
                    </div>
                </form>
            </div>
            <hr></hr>
            <div>
                <p>Historia e mungesave te planifikuara</p>

                <div className='row'>
                    <div className='col-md-6'>
                        {loading && <LinearProgress color="primary" className="mt-1" />}
                        <div className='mt-3'>
                            {absences && absences.length > 0 ? (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Data</b></TableCell>
                                                <TableCell><b>Arsyeja</b></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {absences.map((item, index) => (
                                                <TableRow hover key={index}>
                                                    <TableCell>{moment(item.missing_date).format('LL')}</TableCell>
                                                    <TableCell>{item.reason}</TableCell>
                                                    <TableCell>
                                                        <IconButton disabled={loading} onClick={() => deleteAbsenceeHandler(item.id)}>
                                                            <DeleteIcon fontSize='small' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <p>Nuk ka mungesa te planifikuara per kete student</p>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        {loadingHistory && <LinearProgress color="primary" className="mt-1" />} 
                        <div className='row mt-3'>
                            <div className='col-md-6'>
                                <FormControl fullWidth size='small' required>
                                    <InputLabel id="demo-simple-select-label">Selekto muajin</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={month}
                                        label="Selekto muajin"
                                        name="device_id"
                                        onChange={(e) => setMonth(e.target.value)}
                                    >
                                        {monthsOptions.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-6'>
                                <FormControl fullWidth size='small' required>
                                    <InputLabel id="demo-simple-select-label">Selekto vitin</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={year}
                                        label="Selekto vitin"
                                        name="device_id"
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        {yearsOptions.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='mt-3'>
                            {absenceHistory && absenceHistory.length > 0 ? (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Data</b></TableCell>
                                                <TableCell><b>Arsyeja</b></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {absenceHistory.map((item, index) => (
                                                <TableRow hover key={index}>
                                                    <TableCell>{moment(item.missing_date).format('LL')}</TableCell>
                                                    <TableCell>{item.reason}</TableCell>
                                                    <TableCell>
                                                        <IconButton disabled={loadingHistory} onClick={() => deleteAbsenceeHandler(item.id)}>
                                                            <DeleteIcon fontSize='small' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <p>{month && year ? 'Nuk ka mungesa te planifikuara per kete student ne muajin dhe vitin qe keni zgjedhur' : 'Ju lutem zgjidhni muajin dhe vitin per tu shfaqur historia e mungesave te planifikuara'}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Absences;