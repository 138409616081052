import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCategories, deleteCategory } from '../../../redux/actions/kindergarten/categories';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, IconButton } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styles from './styles.module.scss';
import AddCategory from './forms/AddCategory';
import EditCategory from './forms/EditCategory';

const Categories = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories.categories);

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getCategories()).then(res => {
            setLoading(false);
        })
    }, [dispatch]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const onFinish = () => {
        setForm("none");
        setSelectedItem();
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getCategories()).then(res => {
            setLoading(false);
        })
    }

    const deleteHandler = (id) => {
        setLoading(true);
        dispatch(deleteCategory(id)).then(res => {
            dispatch(getCategories()).then(res => {
                setLoading(false);
            })
        })
    }

    const editHandler = (item) => {
        setSelectedItem(item);
        setForm("edit");
        dispatch(toggleDrawer(true));
    }

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p style={{ padding: 0, margin: 0 }}>Klasat / Kategoritë</p>
                        <Button 
                            variant="contained" 
                            onClick={openAddForm}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </Button>
                    </div>
                    <hr></hr>
                    <div className='row mt-5'>
                        {categories && categories.length > 0 && categories.map(item => (
                            <div className='col-md-3'>
                                <div className={styles.CategoryCard}>
                                    <p>{item.name}</p>
                                    <IconButton onClick={() => editHandler(item)}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                    <IconButton onClick={() => deleteHandler(item.id)}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>

                    <RightDrawer
                        title={form === "add" ? "Krijo klasë / kategori" : "Ndrysho klasën / kategorinë"}
                        width={600}
                    >
                        {form === "add" && <AddCategory onFinish={onFinish} /> }
                        {form === "edit" && <EditCategory onFinish={onFinish} item={selectedItem} />}
                    </RightDrawer>
                </div>
            )}
        </div>
    );
};

export default Categories;