import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box, Typography, Tab, Tabs, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { toggleDrawer } from '../../../redux/actions/app';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import Add from './forms/Add';
import { getEvents, getEventsFromParents, getEventsFromTeachers } from '../../../redux/actions/kindergarten/events';
import styles from './styles.module.scss'
import moment from 'moment/moment';
import EventInfo from './EventInfo';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Events = () => {
    const dispatch = useDispatch();
    const events = useSelector(state => state.events.events);
    const teachersEvents = useSelector(state => state.events.teachersEvents);
    const parentsEvents = useSelector(state => state.events.parentsEvents);

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();
    const [value, setValue] = useState(0);

    const [form, setForm] = useState("none");

    const openAddForm = () => {
      setForm("add");
      dispatch(toggleDrawer(true));
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
      dispatch(getEvents()).then(res => {
        dispatch(getEventsFromParents()).then(res => {
          dispatch(getEventsFromTeachers()).then(res => {
            setLoading(false);
          })
        })
      })
    }, [dispatch]);

    const onFinish = () => {
      setLoading(true);
      dispatch(toggleDrawer(false));
      dispatch(getEvents()).then(res => {
        setLoading(false);
      })
    }

    const selectEvent = (item) => {
      setSelectedItem(item);
      setForm('info');
      dispatch(toggleDrawer(true));
    }

    return (
        <div>
          <div className='d-flex justify-content-between align-items-center'>
              <p style={{ padding: 0, margin: 0 }}></p>
              <Button 
                  variant="contained" 
                  onClick={openAddForm}
              >
                  <AddCircleOutlineIcon fontSize="small" />
              </Button>
          </div>
          <hr></hr>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '30px' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Eventet e krijuara nga cerdhja" {...a11yProps(0)} />
                  <Tab label="Eventet e krijuara nga mesuesit" {...a11yProps(1)} />
                  <Tab label="Eventet e krijuara nga prinderit" {...a11yProps(2)} />
              </Tabs>
          </Box>
          {loading ? (
              <div className='text-center mt-3'>
                  <CircularProgress />
              </div>
          ) : (
              <div>
                  <CustomTabPanel value={value} index={0}>
                      <div className='row'>
                        {events && events.length > 0 && events.map((item, index) => (
                          <div key={index} className='col-md-6' onClick={() => selectEvent(item)}>
                            <div className={styles.EventWrapper}>
                              <p className={styles.Title}>{item.title}</p>
                              <p className={styles.Location}>{item.location}</p>
                              <p className={styles.Date}>{moment(item.date).format('LL')}</p>
                              <p className={styles.Invited}>{item.invites.length} te ftuar</p>
                            </div>
                          </div>
                        ))}
                      </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className='row'>
                        {teachersEvents && teachersEvents.length > 0 && teachersEvents.map((item, index) => (
                          <div key={index} className='col-md-6'>
                            <div className={styles.EventWrapper}>
                              <p className={styles.Title}>{item.title}</p>
                              <p className={styles.Location}>{item.location}</p>
                              <p className={styles.Date}>{moment(item.date).format('LL')}</p>
                              <p className={styles.Invited}>{item.invites.length} te ftuar</p>
                            </div>
                          </div>
                        ))}
                      </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <div className='row'>
                        {parentsEvents && parentsEvents.length > 0 && parentsEvents.map((item, index) => (
                          <div key={index} className='col-md-6'>
                            <div className={styles.EventWrapper}>
                              <p className={styles.Title}>{item.title}</p>
                              <p className={styles.Location}>{item.location}</p>
                              <p className={styles.Date}>{moment(item.date).format('LL')}</p>
                              <p className={styles.Invited}>{item.invites.length} te ftuar</p>
                            </div>
                          </div>
                        ))}
                      </div>
                  </CustomTabPanel>
              </div>
          )}
          <RightDrawer
              title={form === "add" ? "Krijo event" : selectedItem?.title}
              width={600}
          >
              {form === "add" && <Add onFinish={onFinish} /> }
              {form === 'info' && <EventInfo event={selectedItem} onFinish={onFinish} />}
          </RightDrawer>
        </div>
    );
};

export default Events;