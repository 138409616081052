import * as c from "../../constants";
import API from '../../api';

export const getFoodsDaily = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/food-daily`, config)

        dispatch({ type: c.GET_FOOD_DAILY, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const storeFoodDaily = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/food-daily`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const updateFoodDaily = (data, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/food-daily/${id}`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const deleteFoodDaily = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/food-daily/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}