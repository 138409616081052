import * as c from "../../constants";

const initialState = () => {
    return {
        presence: [],
    }
}

export const presenceReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_PRESENCE_HISTORY:
            return { ...state, presence: action.payload }
        default:
            return state
    }
}
