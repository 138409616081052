import * as c from "../../constants";

const initialState = () => {
    return {
        kindergarten: [],
    }
}

export const kindergartenReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_KINDERGARTENS:
            return { ...state, kindergarten: action.payload }
        default:
            return state
    }
}
