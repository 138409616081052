import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storeExpense } from '../../../../redux/actions/kindergarten/expenses';
import toast from 'react-hot-toast';
import { monthsOptions } from '../../../../helpers/months';
import { yearsOptions } from '../../../../helpers/years';

const Add = ({ onFinish, students }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [who, setWho] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "who": who,
            "description": description,
            "price": price,
        };

        dispatch(storeExpense(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Kush?"
                    size="small"
                    value={who}
                    onChange={(e) => { setWho(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Përshkrimi i pagesës?"
                    size="small"
                    value={description}
                    onChange={(e) => { setDescription(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Pagesa"
                    size="small"
                    value={price}
                    onChange={(e) => { setPrice(e.target.value) }}
                    required
                />
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Ruaj
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Add;