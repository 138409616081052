import React, { useState, useEffect } from 'react';
import { Card, CardContent, Button, TextField, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { loginUser } from "../../redux/actions/auth";
import toast from 'react-hot-toast';
import ekopshtiLogo from '../../assets/color-logo.png';

import styles from "./login.module.scss";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({    
        email: yup.string('Shkruaj emailin').email("Duhet te jete email valid").required('Emaili eshte i domosdoshem'),
        password: yup.string('Shkruaj fjalekalimin').min(6, 'Fjalekalimi duhet te jete se paku 6 karaktere').required('Fjalekalimi eshte i domosdoshem'),
    });

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if(user && user.role_id === 1){
            navigate("/admin");
        }
        if(user && user.role_id === 2){
            navigate("/kindergarten");
        }
    }, [user, navigate])

    const formik = useFormik({
        initialValues: {
          email: "",
          password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            dispatch(loginUser(values.email, values.password)).then(res => {
                setLoading(false);
                if(res.data.user.role_id === 1){
                    navigate('/admin');
                }
                if(res.data.user.role_id === 2){
                    navigate('/kindergarten');
                }
            }).catch(e => {
                setLoading(false);
                if(e.data.message === "Invalid credentials"){
                    toast.error('Kredenciale te gabuara!');
                }else{
                    toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
                }
            });
        },
    });

    return (
        <div className={styles.LoginWrapper}>
            <div className='d-flex justify-content-center'>
                <div>
                    <div className='text-center'>
                        <img alt="eKopshti-logo" src={ekopshtiLogo} width="300px" onClick={() => navigate('/')} />
                    </div>
                    <Card className={styles.LoginCard}>
                        <div className='text-center mb-5'>
                            <h2 className='main-title'>Kyçu</h2>
                            <h5 className='main-subtitle'>Ju lutem plotësoni të dhënat më poshtë për të hyrë llogarinë tuaj.</h5>
                        </div>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='mt-2'>
                                    <TextField
                                        autoFocus
                                        name="email"
                                        variant="outlined"
                                        autoComplete='nope'
                                        fullWidth
                                        label="E-mail"
                                        size="small"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <TextField
                                        name="password"
                                        variant="outlined"
                                        autoComplete='new-password'
                                        fullWidth
                                        label="Fjalëkalimi"
                                        size="small"
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </div>    
                                <div className="text-center mt-3">
                                    <Button fullWidth variant="contained" type="submit">Kyqu</Button>
                                </div>
                                {loading && (
                                    <div className='mt-3'>
                                        <LinearProgress />
                                    </div>
                                )}
                            </form>
                        </CardContent>
                        <div className='d-flex justify-content-between mt-3 mx-3'>
                            <p style={{ cursor: 'pointer', fontSize: '13px' }} onClick={() => navigate('/')}>Rreth Nesh</p>
                            <p style={{ cursor: 'pointer', fontSize: '13px' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Login;