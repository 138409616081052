import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { deleteExpense, getExpenses } from '../../../redux/actions/kindergarten/expenses';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Add from './forms/Add';
import { monthsOptions, getCurrentMotnh } from '../../../helpers/months';
import { yearsOptions, getCurrentYear } from '../../../helpers/years';

const Expenses = () => {
    const dispatch = useDispatch();
    const drawer = useSelector(state => state.app.isRightDrawerOpen);
    const expenses = useSelector(state => state.expenses.expenses);

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();
    const [totalPrice, setTotalPrice] = useState(0);

    const [type, setType] = useState('monthly');
    const [month, setMonth] = useState(getCurrentMotnh());
    const [year, setYear] = useState(getCurrentYear());

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getExpenses(type, month, year)).then(res => {
            setLoading(false);
        })
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        dispatch(getExpenses(type, month, year)).then(res => {
            setLoading(false);
        })
    }, [type, month, year]);

    useEffect(() => {
        let price = 0;
        expenses.forEach(element => {
            price += element.price;
        });
        setTotalPrice(price);
    }, [expenses]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const selectItem = (item) => {
        setForm("edit");
        dispatch(toggleDrawer(true));
        setSelectedItem(item);
    }

    const onFinish = () => {
        setForm("none");
        setSelectedItem();
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getExpenses(type, month, year)).then(res => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (!drawer) {
            setSelectedItem();
            setForm("none");
        }
    }, [drawer]);

    const deleteExpenseHandler = (id) => {
        setLoading(true);
        dispatch(deleteExpense(id)).then(res => {
            dispatch(getExpenses(type, month, year)).then(res => {
                setLoading(false);
            })
        })
    }

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Filtro sipas</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Filtro sipas"
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <MenuItem key="1" value="monthly">Sipas muajit</MenuItem>
                                    <MenuItem key="2" value="yearly">Sipas vitit</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Viti</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={year}
                                    label="Viti"
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    {yearsOptions.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3'>
                            {type === 'monthly' && (
                                <FormControl fullWidth size='small' required>
                                    <InputLabel id="demo-simple-select-label">Muaji</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={month}
                                        label="Muaji"
                                        onChange={(e) => setMonth(e.target.value)}
                                    >
                                        {monthsOptions.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className='col-md-4 d-flex justify-content-end'>
                            <Button 
                                variant="contained" 
                                onClick={openAddForm}
                            >
                                <AddCircleOutlineIcon fontSize="small" />
                            </Button>
                        </div>
                    </div>
                    <hr></hr>
                    {totalPrice > 0 && (
                        <p className='mb-2'>Totali: {totalPrice} €</p>
                    )}
                    <div>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Personi</b></TableCell>
                                        <TableCell><b>Çmimi</b></TableCell>
                                        <TableCell><b>Përshkrimi</b></TableCell>
                                        <TableCell><b>Data</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {expenses && expenses.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>
                                                {item.who}
                                            </TableCell>
                                            <TableCell>
                                                {item.price} €
                                            </TableCell>
                                            <TableCell>
                                                {item.description}
                                            </TableCell>
                                            <TableCell>
                                                {moment(item.created_at).format('LLL')}
                                            </TableCell>
                                            <TableCell>
                                                {/* <ReactToPrint
                                                    onBeforeGetContent={() => {
                                                        setPaymentPrint(item);
                                                        return componentRef.current;
                                                    }}
                                                    trigger={() => (
                                                        <IconButton>
                                                            <LocalPrintshopIcon fontSize='small' />
                                                        </IconButton>
                                                    )}
                                                    content={() => componentRef}
                                                /> */}
                                                <IconButton disabled={loading} onClick={() => deleteExpenseHandler(item.id)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {expenses.length === 0 && (
                            <p className='text-center mt-4'>Nuk ka të dhëna</p>
                        )}
                    </div>
                </div>
            )}

            <RightDrawer
                title={form === "add" ? "Shpenzim i ri" : ""}
                width={600}
            >
                {form === "add" && <Add onFinish={onFinish} /> }
            </RightDrawer>
        </div>
    );
};

export default Expenses;