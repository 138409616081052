import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storeFoodDaily } from '../../../../redux/actions/kindergarten/food';
import toast from 'react-hot-toast';
import { daysOptions } from '../../../../helpers/days';

const AddFood = ({ onFinish }) => {
    const dispatch = useDispatch();
    const foods = useSelector(state => state.foodDaily.foodDaily);

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [day, setDay] = useState();
    const [type, setType] = useState();

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "title": title,
            "description": description,
            "day": day,
            "type": type
        };

        dispatch(storeFoodDaily(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Ushqimi"
                    size="small"
                    value={title}
                    onChange={(e) => { setTitle(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Pershkrimi i ushqimit"
                    size="small"
                    value={description}
                    onChange={(e) => { setDescription(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-3'>
                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Selekto ditën</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={day}
                        label="Selekto ditën"
                        onChange={(e) => setDay(e.target.value)}
                    >
                        {daysOptions.map((item) => {
                            const items = foods.filter((f) => f.day === item.value);
                            if (items.length < 3) {
                                return <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                            } 
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-3'>
                <FormControl fullWidth size='small' required disabled={!day}>
                    <InputLabel id="demo-simple-select-label">Koha ushqimit</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Koha ushqimit"

                        onChange={(e) => setType(e.target.value)}
                    >
                        {!foods.some((f) => f.day === day && f.type === 'breakfast') && (
                            <MenuItem key="breakfast" value="breakfast">
                                Mengjesi
                            </MenuItem>
                        )}
                        {!foods.some((f) => f.day === day && f.type === 'lunch') && (
                            <MenuItem key="lunch" value="lunch">
                                Dreka
                            </MenuItem>
                        )}
                        {!foods.some((f) => f.day === day && f.type === 'snacks') && (
                            <MenuItem key="snacks" value="snacks">
                                Snacks
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Shto ushqimin
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default AddFood;