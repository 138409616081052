import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, LinearProgress } from '@mui/material';
import { updateProfile, uploadLogo, changePassword } from '../../../redux/actions/kindergarten/profile';
import toast from 'react-hot-toast';

const Profile = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    const [loading, setLoading] = useState(false);
    const [uploadLogoLoading, setUploadLogoLoading] = useState(false);
    const [passwordLoading, setPasswordLoading] = useState(false);
    const [name, setName] = useState(user.name ?? '');
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');

    const onSubmitUpdateMainProfile = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "name": name,
        };

        dispatch(updateProfile(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    const changePasswordHandler = (e) => {
        e.preventDefault();
        setPasswordLoading(true);

        const data = {
            "current_password": currentPassword,
            "new_password": password
        };

        dispatch(changePassword(data)).then(res => {
            setPasswordLoading(false);
            toast.success('Fjalekalimi u ndryshua me sukses!');
            setCurrentPassword('');
            setPassword('');
        }).catch(err => {
            setPasswordLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    const handleFileSelect = async (event) => {
        setUploadLogoLoading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        dispatch(uploadLogo(formData)).then(res => {
            setUploadLogoLoading(false);
            toast.success('Logo eshte ruajtur me sukses!');
        }).catch(err => {
            setUploadLogoLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    };

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <p style={{ padding: 0, margin: 0 }}>Të dhënat primare | Ndryshimi i emrit</p>
            </div>
            <hr></hr>
            <form autoComplete="off" onSubmit={onSubmitUpdateMainProfile}>
                <div className='row'>
                    <div className='col-md-6 mt-2'>
                        <div>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label="E-mail"
                                disabled
                                size="small"
                                value={user.email}
                                required
                            />
                        </div>
                    </div>
                    <div className='col-md-6 mt-2'>
                        <div>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label="Emri i kopshtit"
                                size="small"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <Button variant="contained" type="submit" disabled={loading}>
                        Përditëso
                    </Button>
                    {loading && <LinearProgress color="primary" className="mt-1" />}               
                </div>
            </form>

            <div className='d-flex justify-content-between align-items-center mt-5'>
                <p style={{ padding: 0, margin: 0 }}>Shto logon | Kliko mbi logo dhe ndrysho</p>
            </div>
            <hr></hr>
            {!uploadLogoLoading ? (
                user.kindergarten_profile.logoUrl ? (
                    <>
                        <img src={user.kindergarten_profile.logoUrl} alt="Business Logo" style={{ width: '100px', cursor: 'pointer' }} onClick={() => document.getElementById('fileInput').click()} />
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />
                    </>
                ) : (
                    <div>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />
                        <Button variant="contained" type="submit" onClick={() => document.getElementById('fileInput').click()}>
                            Ngarko logo
                        </Button>
                    </div>
                )
            ) : (
                <LinearProgress color="primary" className="mt-1" />
            )}

            <div className='d-flex justify-content-between align-items-center mt-5'>
                <p style={{ padding: 0, margin: 0 }}>Ndryshimi i fjalekalimit</p>
            </div>
            <hr></hr>
            <form autoComplete="off" onSubmit={changePasswordHandler}>
                <div className='row'>
                    <div className='col-md-6 mt-2'>
                        <div>
                            <TextField
                                name="password"
                                type='password'
                                variant="outlined"
                                fullWidth
                                label="Fjalekalimi aktual"
                                size="small"
                                value={currentPassword}
                                onChange={(e) => { setCurrentPassword(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                    <div className='col-md-6 mt-2'>
                        <div>
                            <TextField
                                name="password"
                                type='password'
                                variant="outlined"
                                fullWidth
                                label="Fjalekalimi i ri"
                                size="small"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <Button variant="contained" type="submit" disabled={passwordLoading}>
                        Ndrysho fjalëkalimin
                    </Button>
                    {passwordLoading && <LinearProgress color="primary" className="mt-1" />}               
                </div>
            </form>
        </div>
    )
}

export default Profile