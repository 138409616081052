import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, LinearProgress, TableContainer, Table, TableRow, TableHead, TableBody, TableCell } from '@mui/material';
import moment from 'moment';
import { getStudentMedicalLog } from '../../../redux/actions/kindergarten/students';

const MedicalLogs = ({ studentId }) => {
    const dispatch = useDispatch();
    const logs = useSelector(state => state.students.student_medical_logs);

    var today = new Date();
    var year = today.getFullYear();
    var month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1.
    var day = String(today.getDate()).padStart(2, '0');

    var formattedDate = year + '-' + month + '-' + day;

    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(formattedDate);

    useEffect(() => {
        setLoading(true);
        const data = {
            id: studentId,
            date: date
        }
        dispatch(getStudentMedicalLog(data)).then(res => {
            setLoading(false);
        })
    }, [date]);

    return (
        <div>
            <div className='mt-3'>
                <div className='d-flex justify-content-end mb-3'>
                    <div style={{ width: '200px' }}>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label={"Data"}
                            size="small"
                            value={date}
                            type='date'
                            required
                            onChange={(e) => setDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                </div>
                {loading ? 
                    <LinearProgress color="primary" className="mt-1 mb-2" /> 
                : (
                    logs && logs.length > 0 ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Ruajti</b></TableCell>
                                        <TableCell><b>Pershkrimi</b></TableCell>
                                        <TableCell><b>Data</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logs.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{item.created_from === "teacher" ? "Mesuesi" : "Prindi"}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{moment(item.date).format('LL')}</TableCell>
                                            {/* <TableCell>
                                                <IconButton disabled={historyLoading} onClick={() => deletePaymentHandler(item.id)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <p>Nuk ka te dhena per shendetin</p>
                    )
                )}
            </div>
        </div>
    );
};

export default MedicalLogs;