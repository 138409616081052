import * as c from "../../constants";

const initialState = () => {
    return {
        modules: [],
    }
}

export const adminModulesReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_ADMIN_MODULES:
            return { ...state, modules: action.payload }
        default:
            return state
    }
}
