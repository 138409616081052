import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/auth';
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import CelebrationIcon from '@mui/icons-material/Celebration';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import './agency-dashboard.scss';

const Dashboard = ({children}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const [open, setOpen] = useState(false)

    const toggle = () => {
        setOpen(!open)
    }

    const logOutHandler = () => {
        dispatch(logoutUser());
        navigate("/");
    }
    
    return(
        <div className="agency-dashboard">
            <div className={open ? "agency-sidebar-active" : "agency-sidebar"}>

                <div className="logo_content">
                    <div className="logo">
                        
                    </div>
                    <div onClick={toggle} className="toggle-icon" style={{ cursor: 'pointer' }}>
                        {open ? <CloseIcon /> : <MenuIcon />}
                    </div>
                </div>

                {/* <div className="divider"></div> */}

                {user && user.role_id === 1 && (
                    <ul className="nav_list">
                        <li>
                            <Link to="/admin">
                                <div className="icon"><HomeIcon /></div>
                                <span className="link_name">Dashboard</span>
                            </Link>
                            <p className="tooltip">Dashboard</p>
                        </li>
                        <li>
                            <Link to="/admin-kindergarten">
                                <div className="icon"><SchoolIcon /></div>
                                <span className="link_name">Çerdhet</span>
                            </Link>
                            <p className="tooltip">Çerdhet</p>
                        </li>
                        <li>
                            <Link to="/admin-modules">
                                <div className="icon"><WorkspacesIcon /></div>
                                <span className="link_name">Modulet</span>
                            </Link>
                            <p className="tooltip">Modulet</p>
                        </li>
                        <div className="divider">
                            <div className="logout-button" style={{marginBottom: '70px'}}>
                                <LogoutIcon onClick={logOutHandler} />
                            </div>
                        </div>
                    </ul>
                )}

                {user && user.role_id === 2 && (
                    <ul className="nav_list">
                        <li>
                            <Link to="/kindergarten" className={location.pathname === '/kindergarten' ? 'activeLink' : ''}>
                                <div className="icon"><HomeIcon /></div>
                                <span className="link_name">Dashboard</span>
                            </Link>
                            <p className="tooltip">Dashboard</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-staff" className={location.pathname === '/kindergarten-staff' ? 'activeLink' : ''}>
                                <div className="icon"><PeopleIcon /></div>
                                <span className="link_name">Mësueset</span>
                            </Link>
                            <p className="tooltip">Mësueset</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-students" className={location.pathname === '/kindergarten-students' || location.pathname.includes('/kindergarten-student/') ? 'activeLink' : ''}>
                                <div className="icon"><SchoolIcon /></div>
                                <span className="link_name">Nxënësit</span>
                            </Link>
                            <p className="tooltip">Nxënësit</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-teacher-students" className={location.pathname === '/kindergarten-teacher-students' ? 'activeLink' : ''}>
                                <div className="icon"><GroupWorkIcon /></div>
                                <span className="link_name">Mësues - Nxënës</span>
                            </Link>
                            <p className="tooltip">Mësues - Nxënës</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-payments" className={location.pathname === '/kindergarten-payments' ? 'activeLink' : ''}>
                                <div className="icon"><PaidIcon /></div>
                                <span className="link_name">Pagesat</span>
                            </Link>
                            <p className="tooltip">Pagesat</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-events" className={location.pathname === '/kindergarten-events' ? 'activeLink' : ''}>
                                <div className="icon"><CelebrationIcon /></div>
                                <span className="link_name">Evente</span>
                            </Link>
                            <p className="tooltip">Evente</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-expenses" className={location.pathname === '/kindergarten-expenses' ? 'activeLink' : ''}>
                                <div className="icon"><MoneyOffIcon /></div>
                                <span className="link_name">Harxhimet</span>
                            </Link>
                            <p className="tooltip">Harxhimet</p>
                        </li>
                        <li>
                            <Link to="/kindergarten-settings" className={location.pathname.includes('/kindergarten-settings') ? 'activeLink' : ''}>
                                <div className="icon"><SettingsIcon /></div>
                                <span className="link_name">Cilësimet</span>
                            </Link>
                            <p className="tooltip">Cilësimet</p>
                        </li>
                        <div className="divider">
                            <div className="logout-button" style={{marginBottom: '70px', cursor: 'pointer'}}>
                                <LogoutIcon onClick={logOutHandler} />
                            </div>
                        </div>
                    </ul>
                )}
                
            </div>
            <div className={open ? "agency-container-active" : "agency-container"}>
                <div className="container my-5">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;