import * as c from "../../constants";
import API from '../../api';

export const getEvents = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/events`, config)

        dispatch({ type: c.GET_EVENTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getEventsFromTeachers = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/eventsFromTeachers`, config)

        dispatch({ type: c.GET_TEACHERS_EVENTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getEventsFromParents = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/eventsFromParents`, config)

        dispatch({ type: c.GET_STUDENTS_EVENTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const storeEvent = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/events`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const deleteEvent = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/events/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}