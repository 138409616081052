import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getKindergartenGeneralAbsenceMonthSettings, deleteKindergartenGeneralAbsenceMonthSettings } from '../../../redux/actions/kindergarten/settings';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, IconButton } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './styles.module.scss';
import { getMonthName } from '../../../helpers/months';
import AddAbsenceMonth from './forms/AddAbsenceMonth';

const GeneralAbsenceMonthKindergarten = () => {
    const dispatch = useDispatch();
    const absenceMonths = useSelector(state => state.kindergartenSettings.generalSettingsAbsenceMonth);

    const [loading, setLoading] = useState(true);

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getKindergartenGeneralAbsenceMonthSettings()).then(res => {
            setLoading(false);
        })
    }, [dispatch]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const onFinish = () => {
        setForm("none");
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getKindergartenGeneralAbsenceMonthSettings()).then(res => {
            setLoading(false);
        })
    }

    const deleteMonthAbsence = (id) => {
        setLoading(true);
        dispatch(deleteKindergartenGeneralAbsenceMonthSettings(id)).then(res => {
            dispatch(getKindergartenGeneralAbsenceMonthSettings()).then(res => {
                setLoading(false);
            })
        })
    }

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p style={{ padding: 0, margin: 0 }}>Muaj që nuk punohet</p>
                        <Button 
                            variant="contained" 
                            onClick={openAddForm}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </Button>
                    </div>
                    <hr></hr>
                    <div className='row mt-5'>
                        {absenceMonths && absenceMonths.length > 0 && absenceMonths.map(item => (
                            <div className='col-md-3'>
                                <div className={styles.MonthCard}>
                                    <p>{getMonthName(item.month)}</p>
                                    <IconButton onClick={() => deleteMonthAbsence(item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>

                    <RightDrawer
                        title={form === "add" ? "Regjistro muaj që nuk punohet" : ""}
                        width={600}
                    >
                        {form === "add" && <AddAbsenceMonth onFinish={onFinish} /> }
                    </RightDrawer>
                </div>
            )}
        </div>
    );
};

export default GeneralAbsenceMonthKindergarten;