import * as c from "../../constants";

const initialState = () => {
    return {
        staff: [],
    }
}

export const staffReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_STAFF:
            return { ...state, staff: action.payload }
        default:
            return state
    }
}
