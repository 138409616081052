export const daysOptions = [
    {
        "name": 'E Hënë',
        "value": '1',
    },
    {
        "name": 'E Martë',
        "value": '2',
    },
    {
        "name": 'E Mërkurë',
        "value": '3',
    },
    {
        "name": 'E Enjte',
        "value": '4',
    },
    {
        "name": 'E Premte',
        "value": '5',
    },
    {
        "name": 'E Shtunë',
        "value": '6',
    },
    {
        "name": 'E Diel',
        "value": '7',
    },
];

export const getDayName = (day) => {
    if (day === '1') {
        return 'E Hene';
    }
    if (day === '2') {
        return 'E Marte';
    }
    if (day === '3') {
        return 'E Merkure';
    }
    if (day === '4') {
        return 'E Enjte';
    }
    if (day === '5') {
        return 'E Premte';
    }
    if (day === '6') {
        return 'E Shtune';
    }
    if (day === '7') {
        return 'E Diel';
    }
}