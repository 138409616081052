import * as c from "../../constants";

const initialState = () => {
    return {
        expenses: [],
    }
}

export const expensesReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_EXPENSES:
            return { ...state, expenses: action.payload }
        default:
            return state
    }
}
