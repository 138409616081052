import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { storeAdminModule } from '../../../../redux/actions/admin/modules';
import { TextField, Button, LinearProgress } from '@mui/material';
import toast from 'react-hot-toast';

const Add = ({ onFinish }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState();
    const [identifyName, setIdentifyName] = useState();
    const [description, setDescription] = useState();

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "name": name,
            "identify_name": identifyName,
            "description": description,
        };

        dispatch(storeAdminModule(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Emri i modulit"
                            size="small"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Emri identifikimit (slug)"
                            size="small"
                            value={identifyName}
                            onChange={(e) => { setIdentifyName(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Pershkrimi"
                            size="small"
                            multiline
                            rows={3}
                            value={description}
                            onChange={(e) => { setDescription(e.target.value) }}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Krijo modulin
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Add;