import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCategories, connectCategoryModules } from '../../../redux/actions/kindergarten/categories';
import { getAdminModules } from '../../../redux/actions/admin/modules';
import { toggleDrawer } from '../../../redux/actions/app';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import { CircularProgress, Button, IconButton, Tooltip } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import styles from './styles.module.scss';

const ConnectCategoryModules = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories.categories);
    const modules = useSelector(state => state.adminModules.modules);

    const [loading, setLoading] = useState(true);
    const [loadingToggles, setLoadingToggles] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getCategories()).then(res => {
            dispatch(getAdminModules()).then(res => {
                setLoading(false);
            })
        })
    }, [dispatch]);

    const isModuleActiveForThisCategory = (module, category) => {
        const modules = category.modules;
        if (modules.length > 0) {
            const existModule = modules.find(item => item.module_id === module.id);
            if (existModule) {
                return true;
            }
            return false;
        }
        return false;
    }

    const connectCategoryModuleHandler = (categoryId, moduleId) => {
        setLoadingToggles(true);
        const data = {
            "category_id": categoryId,
            "module_id": moduleId
        }
        dispatch(connectCategoryModules(data)).then(res => {
            dispatch(getCategories()).then(res => {
                setLoadingToggles(false);
            })
        })
    }


    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {categories && categories.length > 0 && categories.map((item, index) => (
                        <div className={styles.CategoryWithModulesWrapper} key={index}>
                            <h4>{item.name}</h4>
                            <div className='row'>
                                {modules && modules.map((module, index) => (
                                    <div className='col-md-4' key={index}>
                                        <Tooltip title={module.description}>
                                            <div className={styles.ModuleWrapper}>
                                                <div className='d-flex'>
                                                    {/* <InventoryIcon /> */}
                                                    <p>{module.name}</p>
                                                </div>
                                                <IconButton onClick={() => connectCategoryModuleHandler(item.id, module.id)}>
                                                    {loadingToggles && (
                                                        <CircularProgress size={10} />
                                                    )}
                                                    {!loadingToggles && (
                                                        isModuleActiveForThisCategory(module, item) ? (
                                                            <ToggleOnIcon />
                                                        ) : (
                                                            <ToggleOffOutlinedIcon />
                                                        )
                                                    )}
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ConnectCategoryModules;