import * as c from "../../constants";
import API from '../../api';

export const getPresenceHistory = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/getPresenceHistory?month=${data.month}&year=${data.year}&student_id=${data.student_id}`, config)

        dispatch({ type: c.GET_PRESENCE_HISTORY, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const togglePresenceOfStudent = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/togglePresenceOfStudent`, data, config)

        return response
    } catch (error) {
        throw error
    }
}