export const monthsOptions = [
    {
        "name": 'Janar',
        "value": '1',
    },
    {
        "name": 'Shkurt',
        "value": '2',
    },
    {
        "name": 'Mars',
        "value": '3',
    },
    {
        "name": 'Prill',
        "value": '4',
    },
    {
        "name": 'Maj',
        "value": '5',
    },
    {
        "name": 'Qershor',
        "value": '6',
    },
    {
        "name": 'Korrik',
        "value": '7',
    },
    {
        "name": 'Gusht',
        "value": '8',
    },
    {
        "name": 'Shtator',
        "value": '9',
    },
    {
        "name": 'Tetor',
        "value": '10',
    },
    {
        "name": 'Nëntor',
        "value": '11',
    },
    {
        "name": 'Dhjetor',
        "value": '12',
    },
];

export const getMonthName = (month) => {
    if (month === '1') {
        return 'Janar';
    }
    if (month === '2') {
        return 'Shkurt';
    }
    if (month === '3') {
        return 'Mars';
    }
    if (month === '4') {
        return 'Prill';
    }
    if (month === '5') {
        return 'Maj';
    }
    if (month === '6') {
        return 'Qershor';
    }
    if (month === '7') {
        return 'Korrik';
    }
    if (month === '8') {
        return 'Gusht';
    }
    if (month === '9') {
        return 'Shtator';
    }
    if (month === '10') {
        return 'Tetor';
    }
    if (month === '11') {
        return 'Nentor';
    }
    if (month === '12') {
        return 'Dhjetor';
    }
}

export const getCurrentMotnh = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    return currentMonth;
}