import * as c from "../../constants";

const initialState = () => {
    return {
        students: [],
        student: null,
        student_food_logs: [],
        student_medical_logs: [],
    }
}

export const studentsReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_STUDENTS:
            return { ...state, students: action.payload }
        case c.GET_STUDENT:
            return { ...state, student: action.payload }
        case c.GET_STUDENT_FOOD_LOGS:
            return { ...state, student_food_logs: action.payload }
        case c.GET_STUDENT_MEDICAL_LOGS:
            return { ...state, student_medical_logs: action.payload }
        default:
            return state
    }
}
