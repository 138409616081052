import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storeKindergarten } from '../../../../redux/actions/admin/kindergarten';
import styles from '../styles.module.scss';
import toast from 'react-hot-toast';

const Add = ({ onFinish }) => {
    const dispatch = useDispatch();

    const countries = useSelector(state => state.general.countries);
    const cities = useSelector(state => state.general.cities);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "name": name,
            "email": email,
            "country_id": country,
            "city_id": city,
            "address": address,
            "contact_person": contactPerson,
            "contact_phone": contactPhone,
            "password": password
        };

        dispatch(storeKindergarten(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Emri i çerdhes"
                    size="small"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-2'>
                <FormControl fullWidth size='small' required>
                    <InputLabel id="demo-simple-select-label">Selekto shtetin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={country}
                        label="Selekto shtetin"
                        name="device_id"
                        onChange={(e) => setCountry(e.target.value)}
                    >
                        {countries.length > 0 && countries.map((country) => (
                            <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-2'>
                <FormControl fullWidth size='small' required>
                    <InputLabel id="demo-simple-select-label">Selekto qytetin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={city}
                        label="Selekto shtetin"
                        name="device_id"
                        onChange={(e) => setCity(e.target.value)}
                    >
                        {cities.length > 0 && cities.map((city) => (
                            <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Adresa"
                    size="small"
                    value={address}
                    onChange={(e) => { setAddress(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Emri i personit kontaktues"
                    size="small"
                    value={contactPerson}
                    onChange={(e) => { setContactPerson(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Numri i personit kontaktues"
                    size="small"
                    value={contactPhone}
                    onChange={(e) => { setContactPhone(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="E-mail"
                    size="small"
                    type='email'
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Fjalekalimi"
                    size="small"
                    type='password'
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    required
                />
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Regjistro çerdhen
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Add;