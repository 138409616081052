import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoute from './routes/AdminRoute';
import KindergartenRoute from './routes/KindergartenRoute';
import moment from 'moment';
import 'moment/locale/sq' 

import Login from './pages/login/Login';
import Home from './pages/landing/Home';
import PrivacyPolicy from './pages/landing/PrivacyPolicy';

// Admin
import AdminHome from './pages/admin/home/AdminHome';
import Kindergarten from './pages/admin/kindergarten/Kindergarten';
import Modules from './pages/admin/modules/Modules';

// Kindergarten
import KindergartenHome from './pages/kindergarten/home/KindergartenHome';
import Staff from './pages/kindergarten/staff/Staff';
import Students from './pages/kindergarten/students/Students';
import TeacherStudents from './pages/kindergarten/teacher-students/TeacherStudents';
import KindergartenSettings from './pages/kindergarten/settings/KindergartenSettings';
import GeneralAbsenceMonthKindergarten from './pages/kindergarten/settings/GeneralAbsenceMonthKindergarten';
import Categories from './pages/kindergarten/settings/Categories';
import ConnectCategoryModules from './pages/kindergarten/settings/ConnectCategoryModules';
import Payments from './pages/kindergarten/payments/Payments';
import Payment from './pages/kindergarten/payments/Payment';
import Main from './pages/kindergarten/student-profile/Main';
import Events from './pages/kindergarten/events/Events';
import FoodDaily from './pages/kindergarten/settings/FoodDaily';
import Expenses from './pages/kindergarten/expenses/Expenses';
import Profile from './pages/kindergarten/settings/Profile';


const App = () => {
  moment.locale('sq');
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />

        {/* Admin Routes  */}
        <Route path="/admin" exact element={<AdminRoute><AdminHome /></AdminRoute>} />
        <Route path="/admin-kindergarten" exact element={<AdminRoute><Kindergarten /></AdminRoute>} />
        <Route path="/admin-modules" exact element={<AdminRoute><Modules /></AdminRoute>} />

        {/* Kindergarten Routes */}
        <Route path="/kindergarten" exact element={<KindergartenRoute><KindergartenHome /></KindergartenRoute>} />
        <Route path="/kindergarten-staff" exact element={<KindergartenRoute><Staff /></KindergartenRoute>} />
        <Route path="/kindergarten-students" exact element={<KindergartenRoute><Students /></KindergartenRoute>} />
        <Route path="/kindergarten-student/:id" exact element={<KindergartenRoute><Main /></KindergartenRoute>} />
        <Route path="/kindergarten-teacher-students" exact element={<KindergartenRoute><TeacherStudents /></KindergartenRoute>} />
        <Route path="/kindergarten-payments" exact element={<KindergartenRoute><Payments /></KindergartenRoute>} />
        <Route path="/kindergarten-payment/:id" exact element={<KindergartenRoute><Payment /></KindergartenRoute>} />
        <Route path="/kindergarten-events" exact element={<KindergartenRoute><Events /></KindergartenRoute>} />
        <Route path="/kindergarten-settings" exact element={<KindergartenRoute><KindergartenSettings /></KindergartenRoute>} />
        <Route path="/kindergarten-settings-absence-month" exact element={<KindergartenRoute><GeneralAbsenceMonthKindergarten /></KindergartenRoute>} />
        <Route path="/kindergarten-settings-categories" exact element={<KindergartenRoute><Categories /></KindergartenRoute>} />
        <Route path="/kindergarten-settings-connect-categories-with-modules" exact element={<KindergartenRoute><ConnectCategoryModules /></KindergartenRoute>} />
        <Route path="/kindergarten-settings-foods-daily" exact element={<KindergartenRoute><FoodDaily /></KindergartenRoute>} />
        <Route path="/kindergarten-settings-profile" exact element={<KindergartenRoute><Profile /></KindergartenRoute>} />
        <Route path="/kindergarten-expenses" exact element={<KindergartenRoute><Expenses /></KindergartenRoute>} />

      </Routes>
    </Router>
  );
}

export default App;
