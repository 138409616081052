import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/color-logo.png';
import lightBulb from '../../assets/landing/light-bulb.png';
import kid from '../../assets/landing/kid.png';
import teacherapp from '../../assets/landing/teacherapp.png';
import feed from '../../assets/landing/feed.png';
import webapp from '../../assets/landing/webapp.png';
import parentapp from '../../assets/landing/parentapp.png';
import checkbox from '../../assets/landing/checkbox.png';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className='container my-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <img alt="Logoekopshti" src={logo} width='150px' />
        <button onClick={() => navigate('/login')} style={{ backgroundColor: '#5086CF', border: 'none', borderRadius: '10px', padding: '10px 40px', color: '#fff' }}>Kyqu</button>
      </div>

      <div className='row align-items-center mt-5'>
        <div className='col-md-6'>
          <img alt="lightbulb" src={lightBulb} width="50px" />
          <div style={{ marginLeft: '20px' }}>
            <h3>eKopshti</h3>
            <h2>Hapni derën për të ardhmen.</h2>
            <p>Ndjehuni pranë fëmijës tuaj dhe më të sigurt në çdo moment.</p>

            {/* <h5 className='mt-5'>Na kontaktoni në: +383 49 875 675</h5> */}
          </div>
        </div>
        <div className='col-md-6 text-center'>
          <img src={kid} alt="akid" width="70%" />
        </div>
      </div>

      <div className='row align-items-center' style={{ marginTop: '100px' }}>
        <div className='col-md-6 text-center'>
          <img src={teacherapp} alt="akid" width="70%" />
        </div>
        <div className='col-md-6'>
          <div>
            <h2>Aplikacioni eKopshti</h2>
            <p className='mt-3'>Aplikacioni e-Kopshti ju ndihmon prindve dhe mësuesve të jenë më afër dhe më të informuar.</p>

            <p className='mt-3'><b>Llogaria e mësuesit</b> mund të regjistrojë të gjitha aktivitetet e fëmijëve ditore, duke përfshirë pjesëmarrjen në aktivitete, progresin e tyre akademik, si dhe sjelljen e tyre në kopsht.</p>

            <p className='mt-3'><b>Llogaria e prindit </b>
              shërben për tu informuar në kohë rreth fëmijës së tyre, duke marrë njoftime dhe raporte të rregullta në lidhje me pjesëmarrjen, shëndetin, dhe aktivitetet e fëmijës në kopsht.
              Kjo siguron që prindërit të kenë një kuptim të plotë për përparimin dhe sjelljen e fëmijës së tyre në kopsht, duke i lejuar ata të jenë më të përfshirë dhe të ndjehen më të sigurt në marëdhënien e tyre me
              kopshtin dhe stafin pedagogjik.
            </p>
          </div>
        </div>
      </div>

      <div className='row align-items-center' style={{ marginTop: '100px' }}>
        <div className='col-md-6'>
          <div>
              <h2>Mësuesi informon prindin përmes aplikacionit për:</h2>

              <div className='d-flex align-items-center mt-5'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Pjesëmarrjen e fëmijës: Njofton prindin për praninë e fëmijës për secilën ditë.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Shëndetin e fëmijës: Njofton për ndonjë problem shëndetësor, ndonjë simptomë të ndonjë gripi, temperaturë.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Ushqimi: Informacione rreth ushqimit të ofruar në kopsht</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Aktivitetet e klasës: Informohuni për aktivitetet me foto të ndryshme gjatë ditës</p>
              </div>
            </div>
        </div>
        <div className='col-md-6 text-center'>
        <img src={feed} alt="akid" width="70%" />
        </div>
      </div>

      <div className='row align-items-center' style={{ marginTop: '100px' }}>
        <div className='col-md-6 text-center'>
          <img src={webapp} alt="akid" width="70%" />
        </div>
        <div className='col-md-6'>
          <div>
              <h2>Menaxhimi i kopshtit</h2>
              <p className='mt-3'>
                Menaxhoni stafin, shqyrtoni raportet, ndiqni praninë, dhe menaxhoni regjistrimet. Detyrat tuaja administrative, faturimi, dhe pagesat nuk kanë qenë kurrë më të lehta.
              </p>

              <div className='d-flex align-items-center mt-5'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Regjistroni Prindërit.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Regjistroni Fëmijët me të dhënat e tyre.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Regjistroni pagesat e bëra nga prindërit në sistem.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Krijimi i eventev nga kopshti, mesusi,prindi.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Dhe shumë mundësi të tjera për menaxhim sa më të mirë për kopshtin tuaj.</p>
              </div>
            </div>
        </div>
      </div>

      <div className='row align-items-center' style={{ marginTop: '100px' }}>
        <div className='col-md-6'>
          <div>
              <h2>Për prindërit</h2>

              <div className='d-flex align-items-center mt-5'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Informacion në kohë për pjesëmarrjen e fëmijës.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Njoftime për gjendjen shëndetësore të fëmijës.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Detaje për ushqimin.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Postime ditore për aktivitetet në kopsht.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Mundësi për krijuar evente nga prindërit dhe mesuesit</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Njoftojeni mesuesit për mungesat e fëmijëve.</p>
              </div>
              <div className='d-flex align-items-center mt-3'>
                <img alt="checkboxpoint" src={checkbox} className='me-2' />
                <p>Informacion për pagesat e kopshtit.</p>
              </div>
            </div>
        </div>
        <div className='col-md-6 text-center'>
          <img src={parentapp} alt="akid" width="70%" />
        </div>
      </div>

    </div>
  )
}

export default Home