import * as c from "../../constants";
import API from '../../api';

export const getFromTodayStudentAbsences = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/getFromTodayStudentAbsences/${id}`, config)

        dispatch({ type: c.GET_ABSENCES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getStudentAbsencesBasedOnMonth = (month, year, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/getStudentAbsencesBasedOnMonth/${id}?month=${month}&year=${year}`, config)

        dispatch({ type: c.GET_ABSENCES_HISTORY, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const storeAbsencesForStudent = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/storeAbsencesForStudent`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const deletestudentPlannedAbsence = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/deletestudentPlannedAbsence/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}