import React from 'react';
import Dashboard from "../components/dashboard/Dashboard";
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = ({ children }) => {
    const user = useSelector((state) => state.auth.user);

    return user && user.role_id === 1 ? <Dashboard>{children}</Dashboard> : <Navigate to="/" />
};

export default AdminRoute;