import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { updateStudent, deleteStudent } from '../../../../redux/actions/kindergarten/students';
import toast from 'react-hot-toast';

const Edit = ({ onFinish, item }) => {
    const dispatch = useDispatch();

    const countries = useSelector(state => state.general.countries);
    const cities = useSelector(state => state.general.cities);
    const staff = useSelector(state => state.staff.staff);
    const categories = useSelector(state => state.categories.categories);

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(item.student_profile ? item.student_profile.firstname ?? '' : '');
    const [lastName, setLastName] = useState(item.student_profile ? item.student_profile.lastname ?? '' : '');
    const [country, setCountry] = useState(item.student_profile ? item.student_profile.country_id ?? '' : '');
    const [city, setCity] = useState(item.student_profile ? item.student_profile.city_id ?? '' : '');
    const [address, setAddress] = useState(item.student_profile ? item.student_profile.address ?? '' : '');
    const [birthday, setBirthday] = useState(item.student_profile ? item.student_profile.birthday ?? '' : '');
    const [personalNumber, setPersonalNumber] = useState(item.student_profile ? item.student_profile.personal_number ?? '' : '');
    const [parentNameM, setParentNameM] = useState(item.student_profile ? item.student_profile.parent_name_m ?? '' : '');
    const [parentNameF, setParentNameF] = useState(item.student_profile ? item.student_profile.parent_name_f ?? '' : '');
    const [emergencyPhone, setEmergencyPhone] = useState(item.student_profile ? item.student_profile.emergency_phone ?? '' : '');
    const [contactPhone, setContactPhone] = useState(item.student_profile ? item.student_profile.contact_phone ?? '' : '');
    const [teacherId, setTeacherId] = useState(item.student_profile ? item.student_profile.teacher_id ?? '' : '');
    const [email, setEmail] = useState(item.email ?? '');
    const [password, setPassword] = useState();
    const [startDate, setStartDate] = useState(item.student_profile ? item.student_profile.start_date ?? '' : '');
    const [status, setStatus] = useState(item.student_profile ? item.student_profile.status ?? '' : '');
    const [categoryId, setCategoryId] = useState(item.student_profile ? item.student_profile.category_id ?? '' : '');
    const [gender, setGender] = useState(item.student_profile ? item.student_profile.gender ?? '' : '');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "country_id": country,
            "city_id": city,
            "address": address,
            "birthday": birthday,
            "password": password,
            'personal_number': personalNumber,
            'parent_name_m': parentNameM,
            'parent_name_f': parentNameF,
            'emergency_phone': emergencyPhone,
            'contact_phone': contactPhone,
            'teacher_id': teacherId,
            'start_date': startDate,
            'status': status,
            'category_id': categoryId,
            'gender': gender
        };

        dispatch(updateStudent(data, item.id)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ndryshuar me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            if (err.data === 'email_exists') {
                toast.error('Ky email egziston, ju lutem provoni nje tjeter email!');
            } else {
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            }
        })
    }

    const deleteItem = () => {
        setLoading(true);
        dispatch(deleteStudent(item.id)).then(res => {
            setLoading(false);
            toast.success('Te dhenat u fshine me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Emri"
                            size="small"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Mbiemri"
                            size="small"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto shtetin</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={country}
                                label="Selekto shtetin"
                                name="device_id"
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                {countries.length > 0 && countries.map((country) => (
                                    <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto qytetin</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={city}
                                label="Selekto shtetin"
                                name="device_id"
                                onChange={(e) => setCity(e.target.value)}
                            >
                                {cities.length > 0 && cities.map((city) => (
                                    <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Adresa"
                            size="small"
                            value={address}
                            onChange={(e) => { setAddress(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Ditëlindja"
                            size="small"
                            type='date'
                            InputLabelProps={{ shrink: true }}
                            value={birthday}
                            onChange={(e) => { setBirthday(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Numri personal"
                            size="small"
                            value={personalNumber}
                            onChange={(e) => { setPersonalNumber(e.target.value) }}
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto gjininë</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={gender}
                                label="Selekto gjininë"
                                name="device_id"
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <MenuItem key={'M'} value="M">Mashkull</MenuItem>
                                <MenuItem key={'F'} value="F">Femer</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Emri dhe mbiemri babait"
                            size="small"
                            value={parentNameM}
                            onChange={(e) => { setParentNameM(e.target.value) }}
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Emri dhe mbiemri nënës"
                            size="small"
                            value={parentNameF}
                            onChange={(e) => { setParentNameF(e.target.value) }}
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Numri kontaktues"
                            size="small"
                            value={contactPhone}
                            onChange={(e) => { setContactPhone(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Numrat kontaktues ( emergjent )"
                            size="small"
                            value={emergencyPhone}
                            onChange={(e) => { setEmergencyPhone(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Selekto mësuesen</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={teacherId}
                                label="Selekto mësuesen"
                                name="device_id"
                                onChange={(e) => setTeacherId(e.target.value)}
                            >
                                {staff.length > 0 && staff.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name} {!item.status && '(jo aktiv)'}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto klasën</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={categoryId}
                                label="Selekto klasën"
                                name="device_id"
                                onChange={(e) => setCategoryId(e.target.value)}
                            >
                                {categories.length > 0 && categories.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Dita e parë"
                            size="small"
                            type='date'
                            InputLabelProps={{ shrink: true }}
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'></div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="E-mail"
                            size="small"
                            type='email'
                            value={email}
                            onChange={(e) => { setEmail(e.target.value.toLowerCase()) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Fjalekalimi"
                            size="small"
                            type='password'
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Statusi</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Statusi"
                                name="device_id"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value="active">Nxënës aktiv</MenuItem>
                                <MenuItem value="inactive">Nxënës jo aktiv</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Ndrysho nxënësin
                </Button>
                <Button variant="outlined" type="button" onClick={() => deleteItem()} className='ms-3' disabled={loading}>
                    Fshije nxënësin
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Edit;