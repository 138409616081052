import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteEvent } from '../../../redux/actions/kindergarten/events';
import { Button } from '@mui/material';
import styles from './styles.module.scss';
import toast from 'react-hot-toast';

const EventInfo = ({ event, onFinish }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);    

    const deleteItem = () => {
        setLoading(true);
        dispatch(deleteEvent(event.id)).then(res => {
            setLoading(false);
            toast.success('Te dhenat u fshine me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <div>
            <p>{event.title}</p>
            <p>{event.date}</p>
            <p>{event.location}</p>
            <p>{event.description}</p>
            <div className='text-center'>
                <Button variant="outlined" type="button" onClick={() => deleteItem()} className='mt-3' disabled={loading}>
                    Fshije eventin
                </Button>
            </div>
            <hr></hr>
            <p className='mb-3'>Te ftuarit: </p>
            {event.invites && event.invites.length > 0 && event.invites.map((item, index) => (
                <div key={index} className={styles.InfoEventInvitedWrapper}>
                    <div className='d-flex justify-content-between'>
                        <p>{item.user.name}</p>
                        <p>{item.status}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EventInfo;