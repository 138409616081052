import * as c from "../../constants";
import API from '../../api';

export const updateProfile = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const response = await API.put('/kindergarten-profile', data, config);
        dispatch({ type: c.USER, payload: response.data.user});
        localStorage.setItem('user', JSON.stringify(response.data.user));

        return response
    } catch (error) {
        throw error
    }
}

export const changePassword = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const response = await API.post('/changePassword', data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const uploadLogo = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const response = await API.post('/uploadLogoForMyKindergarten', data, config);
        dispatch({ type: c.USER, payload: response.data.user});
        localStorage.setItem('user', JSON.stringify(response.data.user));

        return response
    } catch (error) {
        throw error
    }
}