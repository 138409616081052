import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#1894d7',
    },
    secondary: {
      main: '#1A2B48',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;