import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherStudents } from '../../../redux/actions/kindergarten/teacherStudents';
import { getStaff } from '../../../redux/actions/kindergarten/staff';
import { CircularProgress } from '@mui/material';
import { toggleDrawer } from '../../../redux/actions/app';
import TeacherStudentsExpandComponent from './TeacherStudentsExapndComponent';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import Edit from './Edit';
import styles from './styles.module.scss';

const TeacherStudents = () => {
    const dispatch = useDispatch();
    const drawer = useSelector(state => state.app.isRightDrawerOpen);
    const teacherStudents = useSelector(state => state.teacherStudents.teacherStudents);

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getTeacherStudents()).then(res => {
            dispatch(getStaff()).then(res => {
                setLoading(false);
            })
        })
    }, [dispatch]);

    const selectItem = (item) => {
        setForm("edit");
        dispatch(toggleDrawer(true));
        setSelectedItem(item);
    }

    const onFinish = () => {
        setForm("none");
        setSelectedItem();
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getTeacherStudents()).then(res => {
            dispatch(getStaff()).then(res => {
                setLoading(false);
            })
        })
    }

    useEffect(() => {
        if (!drawer) {
            setSelectedItem();
            setForm("none");
        }
    }, [drawer]);

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {teacherStudents && teacherStudents.teachers && teacherStudents.teachers.length > 0 && teacherStudents.teachers.map(item => (
                        (item.status || item.students.length > 0) && <TeacherStudentsExpandComponent item={item} selectItem={selectItem} />
                    ))}
                    {teacherStudents && teacherStudents.students && teacherStudents.students.length > 0 && (
                        <TeacherStudentsExpandComponent students={teacherStudents.students} selectItem={selectItem} />
                    )}
                </div>
            )}
            <RightDrawer
                title={form === "add" ? "Krijo student" : "Ndrysho"}
                width={600}
            >
                {form === "edit" && <Edit onFinish={onFinish} item={selectedItem} /> }
            </RightDrawer>
        </div>
    );
};

export default TeacherStudents;