import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCountries, getCities } from '../../../redux/actions/general';
import { getKindergartens } from '../../../redux/actions/admin/kindergarten';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import Add from './forms/Add';
import Edit from './forms/Edit';
import styles from './styles.module.scss';

const Kindergarten = () => {
    const dispatch = useDispatch();
    const drawer = useSelector(state => state.app.isRightDrawerOpen);
    const kindergartens = useSelector(state => state.kindergarten.kindergarten);

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getCountries()).then(res => {
            dispatch(getCities()).then(res => {
                dispatch(getKindergartens()).then(res => {
                    setLoading(false);
                })
            })
        })
    }, [dispatch]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const selectItem = (item) => {
        setForm("edit");
        dispatch(toggleDrawer(true));
        setSelectedItem(item);
    }

    const onFinish = () => {
        setForm("none");
        setSelectedItem();
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getKindergartens()).then(res => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (!drawer) {
            setSelectedItem();
            setForm("none");
        }
    }, [drawer]);

    return (
        <div className={styles.KindergartenWrapper}>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='d-flex justify-content-end'>
                        <Button 
                            variant="contained" 
                            onClick={openAddForm}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </Button>
                    </div>
                    <hr></hr>
                    <div className={styles.TableWrapper}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Emri i cerdhes</b></TableCell>
                                        <TableCell><b>Qyteti / Shteti</b></TableCell>
                                        <TableCell><b>Kontakti</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {kindergartens && kindergartens.map((item, index) => (
                                        <TableRow key={index} className={selectedItem && selectedItem.id === item.id ? styles.ActiveItem : 'on-hover'} onClick={() => selectItem(item)}>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.kindergarten_profile.city.name} / {item.kindergarten_profile.city.country.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.kindergarten_profile.contact_person} - {item.kindergarten_profile.contact_phone}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}

            <RightDrawer
                title={form === "add" ? "Krijo çerdhe" : "Ndrysho çerdhen"}
                width={600}
            >
                {form === "add" && <Add onFinish={onFinish} /> }
                {form === "edit" && <Edit onFinish={onFinish} item={selectedItem} /> }
            </RightDrawer>
        </div>
    );
};

export default Kindergarten;