export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const TOGGLE_LOADING_FORM = "TOGGLE_LOADING_FORM";

export const LOGIN = "LOGIN";
export const USER = "USER";
export const LOGOUT = "LOGOUT";

export const GET_ROLES = "GET_ROLES";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";

// ADMIN
export const GET_KINDERGARTENS = "GET_KINDERGARTENS";
export const GET_ADMIN_MODULES = "GET_ADMIN_MODULES";

// KINDERGARTEN
export const GET_DASHBOARD_INFO = "GET_DASHBOARD_INFO";
export const GET_STAFF = "GET_STAFF";
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENT = "GET_STUDENT";
export const GET_TEACHER_STUDENTS = "GET_TEACHER_STUDENTS";
export const GET_KINDERGARTEN_ABSENCE_MONTH_SETTINGS = "GET_KINDERGARTEN_ABSENCE_MONTH_SETTINGS";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENT = "GET_PAYMENT";
export const GET_EVENTS = "GET_EVENTS";
export const GET_TEACHERS_EVENTS = "GET_TEACHERS_EVENTS";
export const GET_STUDENTS_EVENTS = "GET_STUDENTS_EVENTS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PRESENCE_HISTORY = "GET_PRESENCE_HISTORY";
export const GET_ABSENCES = "GET_ABSENCES";
export const GET_ABSENCES_HISTORY = "GET_ABSENCES_HISTORY";
export const GET_FOOD_DAILY = "GET_FOOD_DAILY";
export const GET_STUDENT_FOOD_LOGS = "GET_STUDENT_FOOD_LOGS";
export const GET_STUDENT_MEDICAL_LOGS = "GET_STUDENT_MEDICAL_LOGS";
export const GET_EXPENSES = "GET_EXPENSES";