import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStudent } from '../../../redux/actions/kindergarten/students';
import { CircularProgress, Box, Typography, Tab, Tabs} from '@mui/material';
import Payments from './Payments';
import Presence from './Presence';
import Absences from './Absences';
import FoodLogs from './FoodLogs';
import MedicalLogs from './MedicalLogs';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Main = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const student = useSelector(state => state.students.student);

    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);

    useEffect(() => {
        setLoading(true);
        dispatch(getStudent(id)).then(res => {
            setLoading(false);
        })
    }, [dispatch, id]);

    const refetchData = () => {
        setLoading(true);
        dispatch(getStudent(id)).then(res => {
            setLoading(false);
        })
    }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Pagesat" {...a11yProps(0)} />
                    <Tab label="Prezenca" {...a11yProps(1)} />
                    <Tab label="Mungesat e planifikuara" {...a11yProps(2)} />
                    <Tab label="Ushqimet" {...a11yProps(3)} />
                    <Tab label="Shendeti" {...a11yProps(4)} />
                </Tabs>
            </Box>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <CustomTabPanel value={value} index={0}>
                        <Payments studentId={id} payments={student.payments} onFinish={refetchData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Presence studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Absences studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <FoodLogs studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <MedicalLogs studentId={id} />
                    </CustomTabPanel>
                </div>
            )}
        </div>
    );
};

export default Main;