import * as c from "../../constants";

const initialState = () => {
    return {
        generalSettingsAbsenceMonth: [],
    }
}

export const kindergartenSettings = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_KINDERGARTEN_ABSENCE_MONTH_SETTINGS:
            return { ...state, generalSettingsAbsenceMonth: action.payload }
        default:
            return state
    }
}
