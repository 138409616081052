import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

const KindergartenSettings = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.SettingsWrapper}>
            <div className='row'>
                <div className='col-md-4 mt-4'>
                    <div className={styles.SettingsCardWrapper} onClick={() => navigate('/kindergarten-settings-profile')}>
                        <p className={styles.Title}>Profili</p>
                        <p className={styles.Description}>
                            Profili i kopshtit, ndryshimi i fjalekalimit.
                        </p>
                    </div>
                </div>
                <div className='col-md-4 mt-4'>
                    <div className={styles.SettingsCardWrapper} onClick={() => navigate('/kindergarten-settings-absence-month')}>
                        <p className={styles.Title}>Muaj që nuk punohet</p>
                        <p className={styles.Description}>
                            Caktoni muajtë që çerdhja juaj nuk jep shërbime.
                        </p>
                    </div>
                </div>
                <div className='col-md-4 mt-4'>
                    <div className={styles.SettingsCardWrapper} onClick={() => navigate('/kindergarten-settings-categories')}>
                        <p className={styles.Title}>Klasat / Kategoritë</p>
                        <p className={styles.Description}>
                            Krijoni kateritë e fëmijëve që çerdhja juaj ofron si shërbim.
                        </p>
                        <p className={styles.Description}>
                            Shembull: Fëmije 0-1, Fëmije 1-2, Parashkollor, Klasa 1-2-3 ...
                        </p>
                    </div>
                </div>
                <div className='col-md-4 mt-4'>
                    <div className={styles.SettingsCardWrapper} onClick={() => navigate('/kindergarten-settings-connect-categories-with-modules')}>
                        <p className={styles.Title}>Cakto modulet për klasa / kategori</p>
                        <p className={styles.Description}>
                            Caktimi i moduleve për secilën klasë / kategori
                        </p>
                        <p className={styles.Description}>
                            Mësueset mund ti përdorin vetëm modulet që i përmbanë klasa / kategoria që i është caktuar
                        </p>
                    </div>
                </div>
                <div className='col-md-4 mt-4'>
                    <div className={styles.SettingsCardWrapper} onClick={() => navigate('/kindergarten-settings-foods-daily')}>
                        <p className={styles.Title}>Ushqimet e ofruara nga çerdhja</p>
                        <p className={styles.Description}>
                            Menaxhimi i ushqimeve që çerdhja ofron sipas ditëve
                        </p>
                        <p className={styles.Description}>
                            Krijimi i menysë së ushqimit
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KindergartenSettings;