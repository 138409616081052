import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getAdminModules } from '../../../redux/actions/admin/modules';
import { toggleDrawer } from '../../../redux/actions/app';
import { CircularProgress, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import styles from './styles.module.scss';
import Add from './forms/Add';

const Modules = () => {
    const dispatch = useDispatch();
    const modules = useSelector(state => state.adminModules.modules);

    const [loading, setLoading] = useState(true);

    const [form, setForm] = useState("none");

    useEffect(() => {
        setLoading(true);
        dispatch(getAdminModules()).then(res => {
            setLoading(false);
        })
    }, [dispatch]);

    const openAddForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const onFinish = () => {
        setForm("none");
        dispatch(toggleDrawer(false));
        setLoading(true);
        dispatch(getAdminModules()).then(res => {
            setLoading(false);
        })
    }

    return (
        <div className={styles.KindergartenWrapper}>
            {loading ? (
                <div className='text-center'>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div className='d-flex justify-content-end'>
                        <Button 
                            variant="contained" 
                            onClick={openAddForm}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </Button>
                    </div>
                    <hr></hr>
                    <div className={styles.TableWrapper}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Moduli</b></TableCell>
                                        <TableCell><b>Slug</b></TableCell>
                                        <TableCell><b>Pershkrimi</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modules && modules.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.identify_name}
                                            </TableCell>
                                            <TableCell>
                                                {item.description}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}

            <RightDrawer
                title={form === "add" ? "Krijo modul te ri" : "Ndrysho modulin"}
                width={600}
            >
                {form === "add" && <Add onFinish={onFinish} /> }
            </RightDrawer>
        </div>
    );
};

export default Modules;