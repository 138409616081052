import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { storeEvent } from '../../../../redux/actions/kindergarten/events';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import toast from 'react-hot-toast';

const Add = ({ onFinish }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState();
    const [location, setLocation] = useState();
    const [date, setDate] = useState();
    const [description, setDescription] = useState();
    const [whoToInvite, setWhoToInvite] = useState();

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "title": title,
            "location": location,
            "date": date,
            "description": description,
            "whotoinvite": whoToInvite
        };

        dispatch(storeEvent(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Titulli eventit"
                            size="small"
                            value={title}
                            onChange={(e) => { setTitle(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Data eventit"
                            size="small"
                            type='date'
                            InputLabelProps={{ shrink: true }}
                            value={date}
                            onChange={(e) => { setDate(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Lokacioni eventit"
                            size="small"
                            value={location}
                            onChange={(e) => { setLocation(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Pershkrimi"
                            size="small"
                            multiline
                            rows={3}
                            value={description}
                            onChange={(e) => { setDescription(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Ke do ftoni ne event?</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={whoToInvite}
                                label="Ke do ftoni ne event?"
                                name="device_id"
                                onChange={(e) => setWhoToInvite(e.target.value)}
                            >
                                <MenuItem value="teachers">Te gjithe mesuesit</MenuItem>
                                <MenuItem value="students">Te gjithe nxenesit</MenuItem>
                                <MenuItem value="everyone">Te gjithe nxenesit dhe mesuesit</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Krijo eventin
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Add;