import React from 'react';
import { useDispatch } from "react-redux";

const AdminHome = () => {
    const dispatch = useDispatch();
    
    return (
        <div>
            <h1>ok</h1>
        </div>
    );
};

export default AdminHome;