import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPresenceHistory, togglePresenceOfStudent } from '../../../redux/actions/kindergarten/presence';
import { getStudentAbsencesBasedOnMonth } from '../../../redux/actions/kindergarten/absences';
import { FormControl, Tooltip, InputLabel, Select, MenuItem, IconButton, CircularProgress } from '@mui/material';
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from 'date-fns';
import { getCurrentMotnh, getMonthName, monthsOptions } from '../../../helpers/months';
import { getCurrentYear, yearsOptions } from '../../../helpers/years';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from 'moment';
import styles from './styles.module.scss';

let today = new Date();

const Presence = ({ studentId }) => {
    const dispatch = useDispatch();
    const historyPresence = useSelector(state => state.presence.presence);
    const absenceHistory = useSelector(state => state.absences.absencesHistory);

    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState(getCurrentMotnh());
    const [year, setYear] = useState(getCurrentYear());
    const firstDayOfMonth = startOfMonth(today);
    const lastDayOfMonth = endOfMonth(today);
    const daysInMonth = eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth });

    useEffect(() => {
        today.setDate(1);
        today.setMonth(month - 1);
    }, [month]);  

    useEffect(() => {
        setLoading(true);
        const data = {
            "student_id": studentId,
            "month": month,
            "year": year
        }
        dispatch(getPresenceHistory(data)).then(res => {
            dispatch(getStudentAbsencesBasedOnMonth(month, year, studentId)).then(res => {
                setLoading(false);
            })
        })
    }, [year, month, studentId, dispatch]);

    const wasTheStudentPresent = (day) => {
        if (historyPresence) {
            const date = moment(day).format('YYYY-MM-DD');
            const isPresent = historyPresence.some(item => item.date === date);
            return isPresent;
        }
        return false;
    }

    const isTheStudentOnPlannedAbsence = (day) => {
        if (absenceHistory) {
            const date = moment(day).format('YYYY-MM-DD');
            const isPresent = absenceHistory.some(item => item.missing_date === date);
            return isPresent;
        }
        return false;
    }

    const togglePresence = (day) => {
        setLoading(true);
        const dataToToggle = {
            "date": moment(day).format('YYYY-MM-DD'),
            "student_id": studentId
        };
        const data = {
            "student_id": studentId,
            "month": month,
            "year": year
        }
        dispatch(togglePresenceOfStudent(dataToToggle)).then(res => {
            dispatch(getPresenceHistory(data)).then(res => {
                setLoading(false);
            })
        })
    }

    const isSunday = (day) => {
        const date = new Date(day);
        const dayOfWeek = date.getDay();
        return dayOfWeek === 0;
    }

    const isSaturday = (day) => {
        const date = new Date(day);
        const dayOfWeek = date.getDay();
        return dayOfWeek === 6;
    }

    return (
        <div>
            <div className='row'>
                <div className='col-md-3 mt-3'>
                    <FormControl fullWidth size='small' required>
                        <InputLabel id="demo-simple-select-label">Selekto muajin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={month}
                            label="Selekto muajin"
                            name="device_id"
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            {monthsOptions.map((item) => (
                                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-md-6 mt-3'>
                    <FormControl fullWidth size='small' required>
                        <InputLabel id="demo-simple-select-label">Selekto vitin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={year}
                            label="Selekto vitin"
                            name="device_id"
                            onChange={(e) => setYear(e.target.value)}
                        >
                            {yearsOptions.map((item) => (
                                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='mt-2'>
                <p>Ditet prezent te nxenesit gjate muajit {getMonthName(month.toString())}: <b>{historyPresence && historyPresence.length} dite prezent.</b></p>
            </div>
            <div className="row">
                {historyPresence && daysInMonth.map((day) => (
                    <div key={day} className="col-md-2 mt-3">
                        <div className={styles.DayCalendarWrapper}>
                            <p className={styles.DayCalendar}>{format(day, 'd')}</p>
                            {loading ? <CircularProgress size={20} /> : 
                                wasTheStudentPresent(day) ? (
                                    <Tooltip title="Nxenesi eshte i regjistruar se ka qene prezent ne kete date. Klikoni per ta ndryshuar">
                                        <IconButton onClick={() => togglePresence(day)}>
                                            <CheckCircleOutlineIcon color='primary' fontSize='large' />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Nxenesi nuk ka qene prezent. Klikoni per ta ndryshuar">
                                        <IconButton  onClick={() => togglePresence(day)}>
                                            <PanoramaFishEyeIcon fontSize='large' />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                            {isSunday(day) && <p className={styles.WeekendDay}>E diell</p>}
                            {isSaturday(day) && <p className={styles.WeekendDay}>E shtune</p>}
                            {isTheStudentOnPlannedAbsence(day) && <p>Mungese e planifikuar</p>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Presence;