import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storeKindergartenGeneralAbsenceMonthSettings } from '../../../../redux/actions/kindergarten/settings';
import toast from 'react-hot-toast';
import { monthsOptions } from '../../../../helpers/months';

const AddAbsenceMonth = ({ onFinish }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "month": month,
        };

        dispatch(storeKindergartenGeneralAbsenceMonthSettings(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-3'>
                <FormControl fullWidth size='small' required>
                    <InputLabel id="demo-simple-select-label">Selekto muajin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={month}
                        label="Selekto muajin"
                        name="device_id"
                        onChange={(e) => setMonth(e.target.value)}
                    >
                        {monthsOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Ruaj
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default AddAbsenceMonth;