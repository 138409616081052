import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress } from '@mui/material';
import { updateCategory } from '../../../../redux/actions/kindergarten/categories';
import toast from 'react-hot-toast';

const EditCategory = ({ onFinish, item }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(item.name);

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "name": name,
        };

        dispatch(updateCategory(data, item.id)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Emri i kategorise"
                    size="small"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                    required
                />
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Ndrysho klasën / kategorinë
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default EditCategory;