import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPayment } from '../../../redux/actions/kindergarten/payments';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { getMonthName } from '../../../helpers/months';
import { Button, IconButton } from '@mui/material';
import moment from 'moment';

const Payment = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const payment = useSelector(state => state.payments.payment);
    const user = useSelector(state => state.auth.user);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        dispatch(getPayment(id)).then(res => {
            setLoading(false);
        })
    }, [dispatch, id]);

    const onPrintPDF = () => {
        window.print();
    }

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                </IconButton>

                <Button variant="contained" type="submit" onClick={() => onPrintPDF()}>
                    Printo
                </Button>
            </div>

            {!loading && (
                <div id='printableArea'>
                    <div style={{ padding: '20px' }} className='mt-5 mb-5'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h4>{user.name}</h4>
                            <img src={user.kindergarten_profile.logoUrl} alt="Business Logo" style={{ width: '100px' }} />
                        </div>

                        <div className='mt-3'>
                            <p>Pagesa për muajin {getMonthName(payment.month)} viti {payment.year} për <b>{payment.student.name}</b>.</p>
                            <p>Çmimi: <b>{payment.price} €</b></p>
                            <p>Data: <b>{moment().format('DD/MM/YYYY')}</b></p>
                        </div>

                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='mt-5'>
                                <p>Pagoi: ____________________________</p>
                                <p className='mt-4'>Nënshkrimi: _______________________</p>
                            </div>
                            <div className='mt-5'>
                                <p>Pranoi: ____________________________</p>
                                <p className='mt-4'>Nënshkrimi: _______________________</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '20px' }} className='mt-5'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h4>{user.name}</h4>
                            <img src={user.kindergarten_profile.logoUrl} alt="Business Logo" style={{ width: '100px' }} />
                        </div>

                        <div className='mt-3'>
                            <p>Pagesa për muajin {getMonthName(payment.month)} viti {payment.year} për <b>{payment.student.name}</b>.</p>
                            <p>Çmimi: <b>{payment.price} €</b></p>
                            <p>Data: <b>{moment().format('DD/MM/YYYY')}</b></p>
                        </div>

                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='mt-5'>
                                <p>Pagoi: ____________________________</p>
                                <p className='mt-4'>Nënshkrimi: _______________________</p>
                            </div>
                            <div className='mt-5'>
                                <p>Pranoi: ____________________________</p>
                                <p className='mt-4'>Nënshkrimi: _______________________</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Payment