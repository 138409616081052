import * as c from "../../constants";

const initialState = () => {
    return {
        events: [],
        teachersEvents: [],
        parentsEvents: [],
    }
}

export const kindergartenEventsReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_EVENTS:
            return { ...state, events: action.payload }
        case c.GET_TEACHERS_EVENTS:
            return { ...state, teachersEvents: action.payload }
        case c.GET_STUDENTS_EVENTS:
            return { ...state, parentsEvents: action.payload }
        default:
            return state
    }
}
