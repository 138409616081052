import * as c from "../../constants";

const initialState = () => {
    return {
        foodDaily: [],
    }
}

export const foodDayilyReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_FOOD_DAILY:
            return { ...state, foodDaily: action.payload }
        default:
            return state
    }
}
