import React, { useState } from 'react';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from './styles.module.scss';

const TeacherStudentsExpandComponent = ({ item, students, selectItem }) => {
    const [expand, setExpand] = useState(false);

    const iStudents = students ?? item.students;

    return (
        <Card className={styles.ExpandComponentWrapper}>
            <div onClick={() => setExpand(!expand)} className={styles.Header}>
               <p>{students && students.length > 0 ? 'Pa mesues' : item.name }</p>
               <IconButton>
                    {!expand ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
               </IconButton>
            </div>
            {expand && (
                <div className={styles.Body}>
                    {iStudents && iStudents.length > 0 && iStudents.map(item => (
                        <div className={styles.StudentItemWrapper} onClick={() => selectItem(students ? item.student_profile : item)}>
                            <p>{students ? item.name : `${item.firstname} ${item.lastname}`}</p>
                        </div>
                    ))}
                </div>
            )}
        </Card>
    );
};

export default TeacherStudentsExpandComponent;