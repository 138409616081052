import * as c from "../../constants";

const initialState = () => {
    return {
        payments: [],
        payment: null
    }
}

export const paymentsReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_PAYMENTS:
            return { ...state, payments: action.payload }
        case c.GET_PAYMENT:
            return { ...state, payment: action.payload }
        default:
            return state
    }
}
