import * as c from "../constants";

const initialState = () => {
    return {
        roles: [],
        countries: [],
        cities: [],
    }
}

export const generalReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_ROLES:
            return { ...state, roles: action.payload }
        case c.GET_COUNTRIES: 
            return { ...state, countries: action.payload }
        case c.GET_CITIES:
            return { ...state, cities: action.payload }
        default:
            return state
    }
}
