import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storePayment } from '../../../../redux/actions/kindergarten/payments';
import toast from 'react-hot-toast';
import { monthsOptions } from '../../../../helpers/months';
import { yearsOptions } from '../../../../helpers/years';

const Add = ({ onFinish, students }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [price, setPrice] = useState('');
    const [studentId, setStudentId] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "month": month,
            "year": year,
            "price": price,
            "student_id": studentId
        };

        dispatch(storePayment(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-3'>
                <FormControl fullWidth size='small' required>
                    <InputLabel id="demo-simple-select-label">Selekto studentin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={studentId}
                        label="Selekto studentin"
                        name="device_id"
                        onChange={(e) => setStudentId(e.target.value)}
                    >
                        {students && students.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-3'>
                <FormControl fullWidth size='small' required>
                    <InputLabel id="demo-simple-select-label">Selekto muajin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={month}
                        label="Selekto muajin"
                        name="device_id"
                        onChange={(e) => setMonth(e.target.value)}
                    >
                        {monthsOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-3'>
                <FormControl fullWidth size='small' required>
                    <InputLabel id="demo-simple-select-label">Selekto vitin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={year}
                        label="Selekto vitin"
                        name="device_id"
                        onChange={(e) => setYear(e.target.value)}
                    >
                        {yearsOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-3'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Pagesa"
                    size="small"
                    value={price}
                    onChange={(e) => { setPrice(e.target.value) }}
                    required
                />
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Ruaj
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Add;