import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, LinearProgress, FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableRow, TableHead, TableBody, TableCell, IconButton } from '@mui/material';
import { storePayment } from '../../../redux/actions/kindergarten/payments';
import toast from 'react-hot-toast';
import { getMonthName, monthsOptions } from '../../../helpers/months';
import { deletePayment } from '../../../redux/actions/kindergarten/payments';
import { getStudent } from '../../../redux/actions/kindergarten/students';
import { yearsOptions } from '../../../helpers/years';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { PaymentInvoice } from '../payments/PaymentInvoice';
import PrintIcon from '@mui/icons-material/Print';

const Payments = ({ onFinish, studentId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const student = useSelector(state => state.students.student);

    const [loading, setLoading] = useState(false);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [price, setPrice] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "month": month,
            "year": year,
            "price": price,
            "student_id": studentId
        };

        dispatch(storePayment(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    
    const deletePaymentHandler = (id) => {
        setHistoryLoading(true);
        dispatch(deletePayment(id)).then(res => {
            dispatch(getStudent(studentId)).then(res => {
                setHistoryLoading(false);
            })
        })
    }

    return (
        <div>
            <div>
                <p>Shto nje pagese te re</p>
                <form className='mt-3' onSubmit={onSubmitForm}>
                    <div className='row'>
                        <div className='col-md-3 mt-3'>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label="Studenti"
                                size="small"
                                value={student.name}
                                disabled
                                required
                            />
                        </div>
                        <div className='col-md-3 mt-3'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Selekto muajin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={month}
                                    label="Selekto muajin"
                                    name="device_id"
                                    onChange={(e) => setMonth(e.target.value)}
                                >
                                    {monthsOptions.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 mt-3'>
                            <FormControl fullWidth size='small' required>
                                <InputLabel id="demo-simple-select-label">Selekto vitin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={year}
                                    label="Selekto vitin"
                                    name="device_id"
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    {yearsOptions.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 mt-3'>
                            <TextField
                                name="name"
                                variant="outlined"
                                fullWidth
                                label="Pagesa"
                                size="small"
                                value={price}
                                onChange={(e) => { setPrice(e.target.value) }}
                                required
                            />
                        </div>
                        <div className="text-center mt-4">
                            <Button variant="contained" type="submit" disabled={loading}>
                                Ruaj
                            </Button>
                            {loading && <LinearProgress color="primary" className="mt-1" />}               
                        </div>
                    </div>
                </form>
            </div>
            <hr></hr>
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <p>Historia e pagesave</p>
                    {historyLoading && <CircularProgress size={20} />}
                </div>
                <div className='mt-3'>
                    {student && student.my_payments && student.my_payments.length > 0 ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Pagesa per periudhen</b></TableCell>
                                        <TableCell><b>Cmimi</b></TableCell>
                                        <TableCell><b>Data e pageses</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {student.my_payments.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{getMonthName(item.month)} / {item.year}</TableCell>
                                            <TableCell>{item.price} €</TableCell>
                                            <TableCell>{moment(item.created_at).format('LLL')}</TableCell>
                                            <TableCell>
                                                {/* <Tooltip title="Kliko per te printuar">
                                                    <ReactToPrint
                                                        trigger={() => (
                                                            <IconButton>
                                                                <LocalPrintshopIcon fontSize='small' />
                                                            </IconButton>
                                                        )}
                                                        content={() => componentRef}
                                                    />
                                                </Tooltip> */}
                                                <IconButton disabled={loading} onClick={() => navigate(`/kindergarten-payment/${item.id}`)}>
                                                    <PrintIcon fontSize='small' />
                                                </IconButton>
                                                <IconButton disabled={historyLoading} onClick={() => deletePaymentHandler(item.id)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <p>Nuk ka pagesa per kete student</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Payments;