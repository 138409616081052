import * as c from "../../constants";

const initialState = () => {
    return {
        teacherStudents: [],
    }
}

export const teacherStudentsReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_TEACHER_STUDENTS:
            return { ...state, teacherStudents: action.payload }
        default:
            return state
    }
}
