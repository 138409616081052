import * as c from "../../constants";
import API from '../../api';

export const getExpenses = (type, month, year) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/expenses?type=${type}&month=${month}&year=${year}`, config)

        dispatch({ type: c.GET_EXPENSES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const storeExpense = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/expenses`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const deleteExpense = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/expenses/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}