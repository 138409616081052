import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getDashboardKindergartenInfos } from '../../../redux/actions/kindergarten/dashboard';
import styles from './styles.module.scss';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const KindergartenHome = () => {
    const dispatch = useDispatch();
    const dashboard = useSelector(state => state.dashboard.info);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getDashboardKindergartenInfos()).then(res => {
            setLoading(false);
        })
    }, []);
    
    return (
        <div>
            {!loading && dashboard && (
                <>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className={styles.StudentWrapper}>
                                <div className='text-center'>
                                    <p>Nxënës aktiv</p>
                                    <h4>{dashboard.students ?? ''}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className={styles.StudentWrapper}>
                                <div className='text-center'>
                                    <p>Mësues aktiv</p>
                                    <h4>{dashboard.teachers ?? ''}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-md-4'>
                            <div className={styles.StudentWrapper}>
                                <div className='text-center'>
                                    <p>Pagesat në muajin aktual</p>
                                    <h4>{dashboard.totalPayments ?? ''} €</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className={styles.StudentWrapper}>
                                <div className='text-center'>
                                    <p>Harxhimet e muajit aktual</p>
                                    <h4>{dashboard.totalExpenses ?? ''} €</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p className='mt-5 mb-2'><b>Pagesat në baza mujore</b></p>
                        <LineChart width={1200} height={300} data={dashboard.monthlyPayments}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </div>

                    <div>
                        <p className='my-3'><b>Shpenzimet në baza mujore</b></p>
                        <LineChart width={1200} height={300} data={dashboard.monthlyExpenses}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </div>
                </>
            )}
        </div>
    );
};

export default KindergartenHome;