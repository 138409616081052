import * as c from "../../constants";
import API from '../../api';

export const getStudents = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/students`, config)

        dispatch({ type: c.GET_STUDENTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getStudent = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/students/${id}`, config)

        dispatch({ type: c.GET_STUDENT, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const storeStudent = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/students`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const updateStudent = (data, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/students/${id}`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const deleteStudent = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/students/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}

export const getStudentFoodLog = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/getStudentFoodLog/${data.id}?date=${data.date}`, config)

        dispatch({ type: c.GET_STUDENT_FOOD_LOGS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getStudentMedicalLog = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/getStudentMedicalLog/${data.id}?date=${data.date}`, config)

        dispatch({ type: c.GET_STUDENT_MEDICAL_LOGS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}