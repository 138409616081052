import * as c from "../../constants";
import API from '../../api';

export const getTeacherStudents = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/teacher-students`, config)

        dispatch({ type: c.GET_TEACHER_STUDENTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}