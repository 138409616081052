import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storeStaff } from '../../../../redux/actions/kindergarten/staff';
import toast from 'react-hot-toast';

const Add = ({ onFinish }) => {
    const dispatch = useDispatch();

    const countries = useSelector(state => state.general.countries);
    const cities = useSelector(state => state.general.cities);
    const categories = useSelector(state => state.categories.categories);

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [birthday, setBirthday] = useState('');
    const [phone, setPhone] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "country_id": country,
            "city_id": city,
            "address": address,
            "birthday": birthday,
            "phone": phone,
            "password": password,
            'category_id': categoryId,
            'gender': gender
        };

        dispatch(storeStaff(data)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ruajtur me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            if (err.data === 'email_exists') {
                toast.error('Ky email egziston, ju lutem provoni nje tjeter email!');
            } else {
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            }
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Emri"
                            size="small"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Mbiemri"
                            size="small"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto gjinine</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={gender}
                                label="Selekto gjinine"
                                name="device_id"
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <MenuItem key={'M'} value="M">Mashkull</MenuItem>
                                <MenuItem key={'F'} value="F">Femer</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto shtetin</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={country}
                                label="Selekto shtetin"
                                name="device_id"
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                {countries.length > 0 && countries.map((country) => (
                                    <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto qytetin</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={city}
                                label="Selekto shtetin"
                                name="device_id"
                                onChange={(e) => setCity(e.target.value)}
                            >
                                {cities.length > 0 && cities.map((city) => (
                                    <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Adresa"
                            size="small"
                            value={address}
                            onChange={(e) => { setAddress(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Numri kontaktues"
                            size="small"
                            value={phone}
                            onChange={(e) => { setPhone(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Ditelindja"
                            size="small"
                            type='date'
                            InputLabelProps={{ shrink: true }}
                            value={birthday}
                            onChange={(e) => { setBirthday(e.target.value) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <FormControl fullWidth size='small' required>
                            <InputLabel id="demo-simple-select-label">Selekto klasen-kategorine</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={categoryId}
                                label="Selekto klasen-kategorine"
                                name="device_id"
                                onChange={(e) => setCategoryId(e.target.value)}
                            >
                                {categories.length > 0 && categories.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="E-mail"
                            size="small"
                            type='email'
                            value={email}
                            onChange={(e) => { setEmail(e.target.value.toLowerCase()) }}
                            required
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mt-3'>
                        <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Fjalekalimi"
                            size="small"
                            type='password'
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                            required
                        />
                    </div>
                </div>
            </div>
            
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Regjistro staf
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Add;