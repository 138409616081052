import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

import { appReducer } from './reducers/app';
import { authReducer } from './reducers/auth';
import { generalReducer } from './reducers/general';
import { kindergartenReducer } from './reducers/admin/kindergarten';
import { staffReducer } from './reducers/kindergarten/staff';
import { studentsReducer } from './reducers/kindergarten/students';
import { teacherStudentsReducer } from './reducers/kindergarten/teacherStudents';
import { kindergartenSettings } from './reducers/kindergarten/settings';
import { paymentsReducer } from './reducers/kindergarten/payments';
import { kindergartenEventsReducer } from './reducers/kindergarten/events';
import { adminModulesReducer } from './reducers/admin/modules';
import { categoriesReducer } from './reducers/kindergarten/categories';
import { presenceReducer } from './reducers/kindergarten/presence';
import { absencesReducer } from './reducers/kindergarten/absences';
import { foodDayilyReducer } from './reducers/kindergarten/food';
import { expensesReducer } from './reducers/kindergarten/expenses';
import { dashboardReducer } from './reducers/kindergarten/dashboard';

const mainReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    general: generalReducer,
    kindergarten: kindergartenReducer,
    staff: staffReducer,
    students: studentsReducer,
    teacherStudents: teacherStudentsReducer,
    kindergartenSettings: kindergartenSettings,
    payments: paymentsReducer,
    events: kindergartenEventsReducer,
    adminModules: adminModulesReducer,
    categories: categoriesReducer,
    presence: presenceReducer,
    absences: absencesReducer,
    foodDaily: foodDayilyReducer,
    expenses: expensesReducer,
    dashboard: dashboardReducer
});

const tokenFromStorage = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token'))
    : null

const userFromStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

const initialState = {
    auth: { token: tokenFromStorage, user: userFromStorage }
}

const middleware = [thunk, logger]

const store = createStore(
    mainReducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store; 