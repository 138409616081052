import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { updateStudent } from '../../../redux/actions/kindergarten/students';
import toast from 'react-hot-toast';

const Edit = ({ onFinish, item }) => {
    const dispatch = useDispatch();
    const staff = useSelector(state => state.staff.staff);

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(item.firstname);
    const [lastName, setLastName] = useState(item.lastname);
    const [teacherId, setTeacherId] = useState(item.teacher_id);

    const onSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            'teacher_id': teacherId,
        };

        dispatch(updateStudent(data, item.user_id)).then(res => {
            setLoading(false);
            toast.success('Te dhenat jane ndryshuar me sukses');
            onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    return (
        <form autoComplete="off" onSubmit={onSubmitForm}>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Emri"
                    size="small"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value) }}
                    required
                    disabled
                />
            </div>
            <div className='mt-2'>
                <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Mbiemri"
                    size="small"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value) }}
                    required
                    disabled
                />
            </div>
            <div className='mt-2'>
                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Selekto mesuesin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={teacherId}
                        label="Selekto mesuesin"
                        name="device_id"
                        onChange={(e) => setTeacherId(e.target.value)}
                    >
                        {staff.length > 0 && staff.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name} {!item.status && '(jo aktiv)'}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit" disabled={loading}>
                    Ndrysho
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default Edit;