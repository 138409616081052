import * as c from "../constants";
import API from '../api';

export const getCountries = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/countries`, config)

        dispatch({ type: c.GET_COUNTRIES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getCities = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/cities`, config)

        dispatch({ type: c.GET_CITIES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getRoles = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/roles`, config)

        dispatch({ type: c.GET_ROLES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}