import * as c from "../../constants";

const initialState = () => {
    return {
        absences: [],
        absencesHistory: [],
    }
}

export const absencesReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_ABSENCES:
            return { ...state, absences: action.payload }
        case c.GET_ABSENCES_HISTORY:
            return { ...state, absencesHistory: action.payload }
        default:
            return state
    }
}
