export const yearsOptions = [
    {
        "name": '2023',
        "value": '2023',
    },
    {
        "name": '2024',
        "value": '2024',
    },
]

export const getCurrentYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear.toString();
}