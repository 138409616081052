import * as c from "../../constants";

const initialState = () => {
    return {
        info: null,
    }
}

export const dashboardReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_DASHBOARD_INFO:
            return { ...state, info: action.payload }
        default:
            return state
    }
}
