import React from 'react';

export class PaymentInvoice extends React.Component {
    render() {
        const { payment } = this.props;
        return (
            <div>
                {payment ? (
                    <div>
                        <h2 style={{color: "green"}}>{payment.student.name}</h2>
                        <p>{payment.month}</p>
                    </div>
                ) : (
                    <p></p>
                )}
            </div>
        );
    }
}